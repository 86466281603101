import { ILang } from './interfaces';
export const services: string[] = [
  'Full service event production',
  'DJ Booking',
  'Video Production',
  'Metaverse production',
  'Sound design',
  'Music production',
  'AI avatar generation',
  'Digital Marketing',
  'Influencer marketing',
  'Sound design for NFTs',
  'Visual identity',
  'Brand narrative',
  'Social media',
  'Architectural design & rendering',
];

export const AppMessages: any = {
  control: {
    required: 'This field is required, please fill it out.',
    contactMessage: 'Please make your message a bit shorter (500 characters max.)',
  },
};

export const MESSAGE_MAX_LENGTH = 500;

export const languages: ILang[] = [
  {
    code: 'en',
    label: 'EN',
    name: 'English',
  },
  {
    code: 'it',
    label: 'IT',
    name: 'Italiano',
  },
];
