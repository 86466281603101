import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppButton from '../../../../components/AppButton';
import AppContext from '../../../../contexts/AppContext';
import { ArrowRight } from '../../../../lib/icons';
import PartnerUpBG from '../../../../assets/images/background/bg-partnerup.jpg';
import VideoPortfolio from '../../../../assets/videos/bg_portfolio_compressed.mp4';
import './PartnerUp.style.css';

const PartnerUp = () => {
  const { t } = useTranslation();
  const { setOnContactDlg } = useContext(AppContext);
  return (
    <div id="home-partner-up">
      <video
        className="background-video"
        autoPlay
        loop
        muted
        playsInline
        poster={PartnerUpBG}
      >
        <source src={VideoPortfolio} type="video/mp4" />
      </video>
      <div className="wrapper">
        <h2>{t('page.home.partner_up.title')}</h2>
        <p>{t('page.home.partner_up.description')}</p>
        <AppButton
          label={t('control.let_us')}
          icon={<ArrowRight />}
          onClick={() => {
            setOnContactDlg(true);
          }}
        />
      </div>
    </div>
  );
};

export default PartnerUp;
