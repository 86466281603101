import './services-pages.css';
import { useTranslation } from 'react-i18next';

const MetaverseAndAI = () => {
  const { t } = useTranslation();
  return (
    <div id="services-metaverse-and-ai-page-layer1">
      <div id="services-page-layer2">
        <div className="wrapper">
          <div style={{ paddingLeft: '5%', paddingTop: '24%' }}>
            <div className="title">
              <h2>{t('page.services.metaverse_and_AI.title')}</h2>
            </div>
            <div className="space-y-4 pt-5 pb-10">
              {/* line1 */}
              <div className="flex flex-wrap space-x-4 ">
                <div className="services-bar">{t('page.services.avatar_creation')}</div>
                <div className="services-bar">{t('page.services.NFT_design')}</div>
              </div>
              {/* line2 */}
              <div className="flex flex-wrap space-x-4 ">
                <div className="services-bar">
                  {t('page.services.metaverse_production')}
                </div>
              </div>
            </div>
            <div className="w-[90%] lg:w-[50%] text-justify pt-5 font-Inter text-white">
              {t('page.services.metaverse_and_AI.description')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetaverseAndAI;
