import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// import MenuEl from './components/MenuEl';
import Calcione from '../../assets/images/home/book/calcione.jpg';
import CastelDelMonte from '../../assets/images/home/book/castel-del-monte.jpg';
import Chiesa from '../../assets/images/home/book/chiesa.jpg';
import Converse from '../../assets/images/home/book/converso.jpg';
import Emanule from '../../assets/images/home/book/emanule.jpg';
import Labirinto from '../../assets/images/home/book/labirinto.jpg';
import Visconti from '../../assets/images/home/book/visconti.jpg';
import BlueBeacon from '../../assets/videos/Blue beacon trimmed.mp4';

const Properties = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="portfolio-page" className="pt-20">
      <div className="grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 aspect-auto">
        <div className="">
          <img className="auto auto" src={Calcione} alt="Ignite"></img>
        </div>
        <div className="">
          <img className="auto auto" src={CastelDelMonte} alt="Chainscore"></img>
        </div>
        <div className="">
          <img className="auto auto" src={Chiesa} alt="Astrace"></img>
        </div>
        <div className="">
          <img className="auto auto" src={Converse} alt="Khloud"></img>
        </div>
        <div className="">
          <img
            className="auto auto"
            style={{ width: '100%' }}
            src={Emanule}
            alt="Spotnik"
          ></img>
        </div>
        <div className="">
          <img className="auto auto" src={Labirinto} alt="LCT"></img>
        </div>
        <div className="">
          <img className="auto auto" src={Visconti} alt="Le Magasin"></img>
        </div>
      </div>
      <div id="partner-name" className="grid grid-cols-4 text-center text-2xl"></div>
      <div className="video-container">
        <div className=""></div>
        <video className="w-full opacity-30" autoPlay muted loop>
          <source src={BlueBeacon} type="video/mp4" />
        </video>
        {/* <div className="bottom-fade"></div> */}
      </div>
    </div>
  );
};

export default Properties;
