import { IPortfolioPieceTOC } from './interfaces';

import IgniteTournamentsTiny from '../../assets/portfolio/decks/covers/Ignite Pitch Deck Opening - tiny.gif';
import IgniteTournamentsInitial from '../../assets/portfolio/decks/covers/Ignite Pitch Deck Opening - initial.gif';
import IgniteTournaments from '../../assets/portfolio/decks/covers/Ignite Pitch Deck Opening.gif';

import AstraceTiny from '../../assets/portfolio/decks/covers/Astrace Pitch Deck Opening - tiny.gif';
import AstraceInitial from '../../assets/portfolio/decks/covers/Astrace Pitch Deck Opening - initial.gif';
import Astrace from '../../assets/portfolio/decks/covers/Astrace Pitch Deck Opening.gif';

import HyperRingTiny from '../../assets/portfolio/decks/covers/HyperRing - tiny.gif';
import HyperRingInitial from '../../assets/portfolio/decks/covers/HyperRing - initial.gif';
import HyperRing from '../../assets/portfolio/decks/covers/HyperRing.gif';

import CryptoRingTiny from '../../assets/portfolio/decks/covers/CryptoRing - tiny.gif';
import CryptoRingInitial from '../../assets/portfolio/decks/covers/CryptoRing - initial.gif';
import CryptoRing from '../../assets/portfolio/decks/covers/CryptoRing.gif';

import PhilippPleinTiny from '../../assets/portfolio/decks/covers/Philipp Plein Cover - tiny.gif';
import PhilippPleinInitial from '../../assets/portfolio/decks/covers/Philipp Plein Cover - initial.gif';
import PhilippPlein from '../../assets/portfolio/decks/covers/Philipp Plein Cover.gif';

import KhloudTiny from '../../assets/portfolio/decks/covers/Khloud Pitch Deck Opening - tiny.gif';
import KhloudInitial from '../../assets/portfolio/decks/covers/Khloud Pitch Deck Opening - initial.gif';
import Khloud from '../../assets/portfolio/decks/covers/Khloud Pitch Deck Opening.gif';

import SpotnikTiny from '../../assets/portfolio/decks/covers/Spotnik Pitch Deck Opening - tiny.gif';
import SpotnikInitial from '../../assets/portfolio/decks/covers/Spotnik Pitch Deck Opening - initial.gif';
import Spotnik from '../../assets/portfolio/decks/covers/Spotnik Pitch Deck Opening.gif';

import LostClubToysTiny from '../../assets/portfolio/decks/covers/LCT Pitch Deck Opening - tiny.gif';
import LostClubToysInitial from '../../assets/portfolio/decks/covers/LCT Pitch Deck Opening - initial.gif';
import LostClubToys from '../../assets/portfolio/decks/covers/LCT Pitch Deck Opening.gif';

import LeMagasinTiny from '../../assets/portfolio/decks/covers/Le Magasin Pitch Deck Opening - tiny.gif';
import LeMagasinInitial from '../../assets/portfolio/decks/covers/Le Magasin Pitch Deck Opening - initial.gif';
import LeMagasin from '../../assets/portfolio/decks/covers/Le Magasin Pitch Deck Opening.gif';

import XoraTiny from '../../assets/portfolio/decks/covers/Xora Pitch Deck Opening - tiny.gif';
import XoraInitial from '../../assets/portfolio/decks/covers/Xora Pitch Deck Opening - initial.gif';
import Xora from '../../assets/portfolio/decks/covers/Xora Pitch Deck Opening.gif';

import VersaceTiny from '../../assets/portfolio/decks/covers/Versace Cover - tiny.gif';
import VersaceInitial from '../../assets/portfolio/decks/covers/Versace Cover - initial.gif';
import Versace from '../../assets/portfolio/decks/covers/Versace Cover.gif';

import PreliosTiny from '../../assets/portfolio/decks/covers/Prelios Pitch Deck Opening - tiny.gif';
import PreliosInitial from '../../assets/portfolio/decks/covers/Prelios Pitch Deck Opening - initial.gif';
import Prelios from '../../assets/portfolio/decks/covers/Prelios Pitch Deck Opening.gif';

import SokailTiny from '../../assets/portfolio/decks/covers/Sokail Pitch Deck Opening - tiny.gif';
import SokailInitial from '../../assets/portfolio/decks/covers/Sokail Pitch Deck Opening - initial.gif';
import Sokail from '../../assets/portfolio/decks/covers/Sokail Pitch Deck Opening.gif';

import ChainscoreTiny from '../../assets/portfolio/decks/covers/Chainscore Pitch Deck Opening - tiny.gif';
import ChainscoreInitial from '../../assets/portfolio/decks/covers/Chainscore Pitch Deck Opening - initial.gif';
import Chainscore from '../../assets/portfolio/decks/covers/Chainscore Pitch Deck Opening.gif';

import IgniteTournamentsDeck from '../../assets-user-triggered/decks/Ignite.pdf';
import AstraceDeck from '../../assets-user-triggered/decks/Astrace.pdf';
import KhloudDeck from '../../assets-user-triggered/decks/Khloud.pdf';
import SpotnikDeck from '../../assets-user-triggered/decks/Spotnik.pdf';
import LostClubToysDeck from '../../assets-user-triggered/decks/LCT.pdf';
import LeMagasinDeck from '../../assets-user-triggered/decks/Le Magasin.pdf';
import XoraDeck from '../../assets-user-triggered/decks/Xora.pdf';
import SokailDeck from '../../assets-user-triggered/decks/Sokail.pdf';
import ChainscoreDeck from '../../assets-user-triggered/decks/Chainscore.pdf';

import portfolioHoverSound from '../../assets/sounds/portfolioHover.wav';
import { createPath } from 'react-router-dom';

export const portfolioPieceTOC: IPortfolioPieceTOC[] = [
  {
    title: 'IgniteTournaments',
    tinyImage: IgniteTournamentsTiny,
    initialImage: IgniteTournamentsInitial,
    image: IgniteTournaments,
    link: IgniteTournamentsDeck,
  },
  {
    title: 'HyperRing',
    tinyImage: HyperRingTiny,
    initialImage: HyperRingInitial,
    image: HyperRing,
    link: 'https://hyperring.ai',
  },
  {
    title: 'Astrace',
    tinyImage: AstraceTiny,
    initialImage: AstraceInitial,
    image: Astrace,
    link: AstraceDeck,
  },
  {
    title: 'Philipp Plein',
    tinyImage: PhilippPleinTiny,
    initialImage: PhilippPleinInitial,
    image: PhilippPlein,
    link: SokailDeck,
  },
  {
    title: 'Khloud',
    tinyImage: KhloudTiny,
    initialImage: KhloudInitial,
    image: Khloud,
    link: KhloudDeck,
  },
  {
    title: 'Xora',
    tinyImage: XoraTiny,
    initialImage: XoraInitial,
    image: Xora,
    link: XoraDeck,
  },
  {
    title: 'Versace',
    tinyImage: VersaceTiny,
    initialImage: VersaceInitial,
    image: Versace,
    link: SokailDeck,
  },
  {
    title: 'Prelios',
    tinyImage: PreliosTiny,
    initialImage: PreliosInitial,
    image: Prelios,
    link: SokailDeck,
  },
  {
    title: 'Chainscore',
    tinyImage: ChainscoreTiny,
    initialImage: ChainscoreInitial,
    image: Chainscore,
    link: ChainscoreDeck,
  },
  {
    title: 'CryptoRing',
    tinyImage: CryptoRingTiny,
    initialImage: CryptoRingInitial,
    image: CryptoRing,
    link: 'https://cryptoring.ai',
  },
  {
    title: 'Sokail',
    tinyImage: SokailTiny,
    initialImage: SokailInitial,
    image: Sokail,
    link: SokailDeck,
  },
  {
    title: 'Spotnik',
    tinyImage: SpotnikTiny,
    initialImage: SpotnikInitial,
    image: Spotnik,
    link: SpotnikDeck,
  },
  {
    title: 'LeMagasin',
    tinyImage: LeMagasinTiny,
    initialImage: LeMagasinInitial,
    image: LeMagasin,
    link: LeMagasinDeck,
  },
  {
    title: 'LostClubToys',
    tinyImage: LostClubToysTiny,
    initialImage: LostClubToysInitial,
    image: LostClubToys,
    link: LostClubToysDeck,
  },
];
