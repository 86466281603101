import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { IServiceTOC } from '../../interfaces';
import servicesHoverSound from '../../../../assets/sounds/servicesHover.wav';
import './ServiceTOCCard.style.css';

const ServiceTOCCard = ({
  title,
  image2,
  image5,
  image10,
  image25,
  image40,
  image,
  serviceUrl,
  playSound,
}: IServiceTOC) => {
  // This is duplicate and inefficient code that needs to be cleaned up later
  const [hasInteracted, setHasInteracted] = useState(false);
  const [image2Loaded, setImage2Loaded] = useState(false);
  const [image5Loaded, setImage5Loaded] = useState(false);
  const [image10Loaded, setImage10Loaded] = useState(false);
  const [image25Loaded, setImage25Loaded] = useState(false);
  const [image40Loaded, setImage40Loaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = image2;
    img.onload = () => setImage2Loaded(true);
  }, [image2]);

  useEffect(() => {
    const img = new Image();
    img.src = image5;
    img.onload = () => setImage5Loaded(true);
  }, [image5]);

  useEffect(() => {
    const img = new Image();
    img.src = image10;
    img.onload = () => setImage10Loaded(true);
  }, [image10]);

  useEffect(() => {
    const img = new Image();
    img.src = image25;
    img.onload = () => setImage25Loaded(true);
  }, [image25]);

  useEffect(() => {
    const img = new Image();
    img.src = image40;
    img.onload = () => setImage40Loaded(true);
  }, [image40]);

  useEffect(() => {
    const img = new Image();
    img.src = image;
    img.onload = () => setImageLoaded(true);
  }, [image]);

  useEffect(() => {
    const enableInteraction = () => setHasInteracted(true);
    window.addEventListener('click', enableInteraction);

    return () => {
      window.removeEventListener('click', enableInteraction);
    };
  }, []);

  const playSoundInternal = () => {
    const audio = new Audio(servicesHoverSound);
    if (hasInteracted) {
      audio.play().catch((error) => console.error('Error playing sound:', error));
    }
  };

  const navigate = useNavigate();

  const handleClick = (serviceUrl: any) => {
    navigate('/' + serviceUrl);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      navigate('/' + serviceUrl);
    }
  };

  const getRandomHexColor = (): string => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Usage
  const randomHexColor: string = getRandomHexColor();

  return (
    <div className="services-service-toc-card service-top-fade">
      <div
        className="card-banner relative"
        // href={serviceUrl}
        // target="_blank"
        // rel="noopener noreferrer"
      >
        <div className="image-container">
          <img
            src={image2}
            alt="card-banner"
            className={`image card-banner w-full h-full object-cover two-percent ${image2Loaded && !imageLoaded ? 'faded-out' : 'faded-in'}`}
          />
          <img
            src={image5}
            alt="card-banner"
            className={`image card-banner w-full h-full object-cover five-percent ${image2Loaded ? 'faded-in' : 'faded-out'}`}
          />
          <img
            src={image10}
            alt="card-banner"
            className={`image card-banner w-full h-full object-cover ten-percent ${image5Loaded ? 'faded-in' : 'faded-out'}`}
          />
          <img
            src={image25}
            alt="card-banner"
            className={`image card-banner w-full h-full object-cover twenty-five-percent ${image10Loaded ? 'faded-in' : 'faded-out'}`}
          />
          <img
            src={image40}
            alt="card-banner"
            className={`image card-banner w-full h-full object-cover forty-percent ${image25Loaded ? 'faded-in' : 'faded-out'}`}
          />
          <img
            src={image}
            alt="card-banner"
            className={`image card-banner w-full h-full object-cover one-hundred-percent ${image40Loaded || imageLoaded ? 'faded-in' : 'faded-out'}`}
          />
        </div>
        <div
          onClick={() => handleClick(serviceUrl)}
          onKeyDown={handleKeyPress}
          onMouseEnter={playSoundInternal}
          role="button"
          tabIndex={0}
          className="absolute inset-0 z-10 flex justify-center items-center text-center text-lg transition-font-size hover:text-2xl font-semibold text-white bg-black bg-opacity-0 hover:bg-gray-700 hover:bg-opacity-50 duration-300 cursor-pointer"
          // style={{ border: `15px solid ${randomHexColor}` }} /* text-opacity-60 */
        >
          {title}
        </div>
      </div>
    </div>
  );
};

export default ServiceTOCCard;
