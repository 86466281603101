import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import detector from 'i18next-browser-languagedetector';

import en from './locales/en.json';
import it from './locales/it.json';

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    resources: {
      en,
      it,
    },
    react: {
      useSuspense: false,
    },
    detection: {
      order: ['localStorage'],
    },
    debug: false,
    fallbackLng: 'en',
    ns: ['translation'],
    defaultNS: 'translation',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  });

export default i18n;
