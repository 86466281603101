import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import { locations } from '../../constants';
import { ILocation } from '../../interfaces';
import AppButton from '../../../../components/AppButton';
import Location from './components/Location';
import AppContext from '../../../../contexts/AppContext';
import { ArrowRight } from '../../../../lib/icons';

import 'swiper/swiper-bundle.css';
import './Book.style.css';

const Book = () => {
  const { t } = useTranslation();
  const [slideIndex, setSlideIndex] = useState(0);
  const { setOnContactDlg } = useContext(AppContext);

  return (
    <div id="home-book">
      <div className="wrapper">
        <h3>{t('page.home.book.sub_title')}</h3>
        <h2>{t('page.home.book.title')}</h2>
        <p>{t('page.home.book.description')}</p>
        <AppButton
          label={t('control.book_now')}
          icon={<ArrowRight />}
          onClick={() => {
            setOnContactDlg(true);
          }}
        />
      </div>
      <div className="slider">
        <span
          className="bg-image"
          style={{ backgroundImage: `url(${locations[slideIndex].image})` }}
        />
        <Swiper
          navigation={true}
          slidesPerView={2}
          spaceBetween={0}
          modules={[Autoplay, Navigation]}
          grabCursor={true}
          speed={500}
          loop={true}
          centeredSlides={true}
          onSlideChange={(e: any) => setSlideIndex(e.realIndex)}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
        >
          {Object.values(locations).map((location: ILocation, key: number) => (
            <SwiperSlide key={key} style={{ opacity: key === slideIndex ? 1 : 0.6 }}>
              <Location
                image={location.image}
                title={location.title}
                subtitle={location.subtitle}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <span className="book-action">
          <AppButton
            label={t('control.book_now')}
            icon={<ArrowRight />}
            onClick={() => {
              setOnContactDlg(true);
            }}
          />
        </span>
      </div>
    </div>
  );
};

export default Book;
