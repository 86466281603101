import { useEffect, useState } from 'react';
import Banner from './containers/Banner/Banner.container';
import ServiceCard from './containers/ServiceCard/ServiceCard.container';
import ServiceTOCCard from './containers/ServiceTOCCard/ServiceTOCCard.container';
import ServicesMatrix from './containers/ServicesMatrix/ServicesMatrix.container';
import BottomSection from './containers/BottomSection/BottomSection.container';
import { IService, IServiceTOC } from './interfaces';
import { services, servicesTOC } from './constants';
import servicesHoverSound from '../../assets/sounds/portfolioHover.wav';
import BlueBeacon from '../../assets/videos/Blue beacon trimmed.mp4';
import './services.page.css';

const Services = () => {
  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const enableInteraction = () => setHasInteracted(true);
    window.addEventListener('click', enableInteraction);

    return () => {
      window.removeEventListener('click', enableInteraction);
    };
  }, []);

  const playSound = () => {
    const audio = new Audio(servicesHoverSound);
    if (hasInteracted) {
      audio.play().catch((error) => console.error('Error playing sound:', error));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="services-page" className="pt-20">
      <ServicesMatrix playSound={playSound} />
    </div>
  );
};

export default Services;
