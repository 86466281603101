import { t } from 'i18next';
import './services-pages.css';
import { useTranslation } from 'react-i18next';

const DJBooking = () => {
  const { t } = useTranslation();
  return (
    <div id="services-dj-booking-page-layer1">
      <div id="services-page-layer2" className="overflow-hidden">
        <div id="services-page-layer3">
          <div className="wrapper">
            <div className="pt-[15%] pl-[5%]">
              <div className="title">
                <h2>{t('page.services.dj_booking.title')}</h2>
              </div>
              <div className="subtitle">
                {/* todo: correct description */}
                {t('page.services.dj_booking.description')}
              </div>
              <div className="services-title">{t('page.services.our_work_included')}</div>
              <div className="space-y-4 pt-5">
                {/* line1 */}
                <div className="flex space-x-4">
                  <div className="services-bar">
                    {t('page.services.music_production')}
                  </div>
                  <div className="services-bar">
                    {t('page.services.artist_management')}
                  </div>
                </div>
                {/* line2 */}
                <div className="flex space-x-4">
                  <div className="services-bar">{t('page.services.music_promotion')}</div>
                  <div className="services-bar">
                    {t('page.services.content_creation')}
                  </div>
                </div>
                {/* line3 */}
                <div className="flex">
                  <div className="services-bar">
                    {t('page.services.event_organization')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DJBooking;
