import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IPortfolioPieceTOC } from '../../interfaces';
import servicesHoverSound from '../../../../assets/sounds/servicesHover.wav';
import './PortfolioPieceTOCCard.style.css';

const PortfolioPieceTOCCard = ({
  title,
  tinyImage,
  initialImage,
  image,
  link,
  playSound,
}: IPortfolioPieceTOC) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = initialImage;
    img.onload = () => setImageLoaded(true);
  }, [initialImage]);

  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const enableInteraction = () => setHasInteracted(true);
    window.addEventListener('click', enableInteraction);

    return () => {
      window.removeEventListener('click', enableInteraction);
    };
  }, []);

  const playSoundInternal = () => {
    const audio = new Audio(servicesHoverSound);
    if (hasInteracted) {
      audio.play().catch((error) => console.error('Error playing sound:', error));
    }
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/' + link);
  };

  return (
    <div
      className="portfolio-portfolioPiece-toc-card hover:bg-gray-100 hover:scale-105 transition duration-500"
      onMouseEnter={playSoundInternal}
      onTouchStart={playSoundInternal}
    >
      <a className="card-banner" href={link} target="_blank" rel="noopener noreferrer">
        <div className="image-container">
          <img
            src={tinyImage}
            alt={title}
            className={`image low-quality ${imageLoaded ? 'faded-out' : 'faded-in'}`}
          />
          <img
            src={initialImage}
            alt={title}
            className={`image medium-quality ${imageLoaded ? 'faded-in' : 'faded-out'}`}
            onLoad={() => setImageLoaded(true)}
          />
          <img
            src={image}
            alt={title}
            className={`image high-quality ${imageLoaded ? 'faded-in' : 'faded-out'}`}
            onLoad={() => setImageLoaded(true)}
          />
        </div>
      </a>
    </div>
  );
};

export default PortfolioPieceTOCCard;
