import { useEffect } from 'react';
import ServiceTOCCard from '../../containers/ServiceTOCCard/ServiceTOCCard.container';
import { IServiceTOC } from '../../interfaces';
import { servicesTOC } from '../../constants';
import '../../services.page.css';

interface ServicesMatrixProps {
  playSound: () => void;
}

const ServicesMatrix: React.FC<ServicesMatrixProps> = ({ playSound }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      // id="services-toc-section"
      className="services-toc-section grid lg:grid-cols-4 sm:grid-cols-1 xs:grid-cols-1 md:grid-cols-2 gap-0"
    >
      {servicesTOC.map((item: IServiceTOC, index: number) => (
        <ServiceTOCCard
          key={`service-toc-card-${index}`}
          title={item.title}
          image2={item.image2}
          image5={item.image5}
          image10={item.image10}
          image25={item.image25}
          image40={item.image40}
          image={item.image}
          serviceUrl={item.serviceUrl}
          playSound={playSound}
        />
      ))}
    </div>
  );
};

export default ServicesMatrix;
