/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';

export interface IAppContext {
  onContactDlg: boolean;
  setOnContactDlg: (val: boolean) => void;
}

export const initialValues: IAppContext = {
  onContactDlg: false,
  setOnContactDlg: () => {},
};

const AppContext = createContext<IAppContext>(initialValues);

export default AppContext;
