import { ReactNode } from 'react';
import './index.css';

export interface IAppBtnProps {
  label: string;
  onClick: () => void;
  icon?: ReactNode;
}

const AppButton = ({ label, onClick, icon }: IAppBtnProps) => {
  return (
    <div aria-hidden className="app-btn-container" onClick={onClick}>
      <div className="app-flex-center">
        <span>{label}</span>
        {icon}
      </div>
    </div>
  );
};

export default AppButton;
