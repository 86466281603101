import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import { services } from '../../../services/constants';
import { IService } from '../../interfaces';
import AppButton from '../../../../components/AppButton';
import Service from './components';
import AppContext from '../../../../contexts/AppContext';
import servicePhraseHoverSound from '../../../../assets/sounds/servicePhrase.wav';
import { ArrowRight } from '../../../../lib/icons';

import 'swiper/swiper-bundle.css';
import '../Book//Book.style.css';

const FeaturedServices = () => {
  const { t } = useTranslation();
  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const enableInteraction = () => setHasInteracted(true);
    window.addEventListener('click', enableInteraction);

    return () => {
      window.removeEventListener('click', enableInteraction);
    };
  }, []);

  const playSound = () => {
    const audio = new Audio(servicePhraseHoverSound);
    if (hasInteracted) {
      audio.play().catch((error) => console.error('Error playing sound:', error));
    }
  };

  return (
    <div id="home-services">
      <div className="wrapper">
        <h3 className="italic">{t('page.home.services.prefix')}</h3>
        <h2>{t('page.home.services.title')}</h2>
        <p className="italic gradient-text">{t('page.home.services.postfix')}</p>
        <div className="italic bold home-services-list text-center gradient-text-container w-full">
          {t('page.home.services.list')
            .split(', ')
            .map((c: string, index: number) => (
              <span
                className="text-magnify pulsate-fast service-phrase"
                key="{index}"
                onMouseEnter={playSound}
                onTouchStart={playSound}
                style={{ animationDelay: `${index * 0.1}s` }} // Apply delay based on index
              >
                <span
                  className="pulsate-wide service-character gradient-text"
                  style={{ animationDelay: `${index * 0.1}s` }} // Apply delay based on index
                >
                  {c}&nbsp;
                </span>
              </span>
            ))}
        </div>
        {/* <AppButton
          label={t('control.book_now')}
          icon={<ArrowRight />}
          onClick={() => {
            setOnContactDlg(true);
          }}
        /> */}
      </div>
      <div className="slider">
        <span
          className="bg-image"
          // style={{ backgroundImage: `url(${services[slideIndex].image})` }}
        />
        {/* <Swiper
          navigation={true}
          slidesPerView={1}
          spaceBetween={0}
          modules={[Autoplay, Navigation]}
          grabCursor={true}
          speed={200}
          loop={true}
          centeredSlides={true}
          onSlideChange={(e: any) => setSlideIndex(e.realIndex)}
          autoplay={{
            delay: 1000,
            disableOnInteraction: true,
          }}
        >
          {Object.values(services).map((service: IService, key: number) => (
            <SwiperSlide key={key} style={{ opacity: key === slideIndex ? 1 : 0.6 }}>
              <Service image={service.image} title={''} />
            </SwiperSlide>
          ))}
        </Swiper> */}
        {/* <span className="book-action">
          <AppButton
            label={t('control.book_now')}
            icon={<ArrowRight />}
            onClick={() => {
              setOnContactDlg(true);
            }}
          />
        </span> */}
      </div>
    </div>
  );
};

export default FeaturedServices;
