import { useState, useEffect, useRef, Fragment, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
// import AppContext from '../../../../contexts/AppContext';
import './GlobalImpact.style.css';
import Orb from '../../../../assets/videos/orb.mp4';
import SingleBeamOfLight from '../../../../assets/videos/Single beam of light.mp4';
import elevateSound from '../../../../assets/sounds/elevate.wav';
import orbSound from '../../../../assets/sounds/orbVisible.wav';
import Letter from '../../../../components/Letter';
import MagnifyingWord from '../../../../components/MagnifyingWord';

// import AppButton from '../../../../components/AppButton';
// import { ArrowRight } from '../../../../lib/icons';

const GlobalImpact = () => {
  const videoRef = useRef(null); // Ref for the div/video to observe
  // const [hasPlayedSound, setHasPlayedSound] = useState(false);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       const [entry] = entries;
  //       if (
  //         entry.isIntersecting
  //         // && !hasPlayedSound
  //       ) {
  //         playOrbSound();
  //         setHasPlayedSound(true); // Ensure sound is played only once
  //       }
  //     },
  //     {
  //       root: null, // Default: browser viewport
  //       rootMargin: '0px',
  //       threshold: 0.1, // Trigger if at least 10% of the element is in view
  //     }
  //   );

  //   if (videoRef.current) {
  //     observer.observe(videoRef.current);
  //   }

  //   return () => {
  //     if (videoRef.current) {
  //       observer.unobserve(videoRef.current);
  //     }
  //   };
  // }, [hasPlayedSound]);

  const playElevateSound = () => {
    const audio = new Audio(elevateSound);
    audio.volume = 0.3;
    // if (hasInteracted) {
    audio.play().catch((error) => console.error('Error playing sound:', error));
    // }
  };

  const playOrbSound = () => {
    const audio = new Audio(orbSound);
    // if (hasInteracted) {
    audio.play().catch((error) => console.error('Error playing sound:', error));
    // }
  };

  const { t } = useTranslation();

  // const { setOnContactDlg } = useContext(AppContext);

  // const handleClick = () => {
  //   setOnContactDlg(true);
  // };
  const text =
    t('page.home.global_impact.description_1') +
    t('page.home.global_impact.description_2');

  // const letters = text.split('').map((letter: string, index: number) =>
  //   letter === ' ' ? (
  //     // Replace spaces with a non-breaking space or use a `span` to keep the layout
  //     <span key={index} className="wave-letter" aria-hidden="true">
  //       &nbsp;
  //     </span>
  //   ) : (
  //     <span key={index} className={`wave-letter wave-letter-${index}`}>
  //       {letter}
  //     </span>
  //   )

  const letters = text.split('').map((letter: string, index: number) => (
    <span
      key={index}
      className="wave-letter"
      style={{ '--delay': `${index * 0.5}s` } as any} // Set the CSS variable for delay
    >
      {letter === ' ' ? '\u00A0' : letter}
    </span>
  ));

  // const words = text.split(' ').map((word: string, wordIndex: number) => (
  //   // Wrap each word in a span to keep it together
  //   <span key={wordIndex} className="word-container" style={{ whiteSpace: 'nowrap' }}>
  //     {word.split('').map((char, charIndex) => (
  //       <span
  //         key={charIndex}
  //         className="wave-letter"
  //         style={{ '--delay': `${wordIndex * 0.1 + charIndex * 0.05}s` } as any}
  //       >
  //         {char}
  //       </span>
  //     ))}
  //     {/* Add a normal space after each word to ensure proper spacing */}{' '}
  //   </span>
  // ));

  const generateDelay = (wordIndex: number, charIndex: number): CSSProperties => ({
    '--delay': `${wordIndex * 0.1 + charIndex * 0.05}s` as unknown as string,
  });

  const words = text.split(' ').map((word: string, wordIndex: number) => (
    // Wrap each word in a span to ensure it's treated as a single unit
    <span key={wordIndex} style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
      {word.split('').map((char: string, charIndex: number) => (
        <span
          key={charIndex}
          className="wave-letter"
          style={
            { '--delay': `${wordIndex * 0.1 + charIndex * 0.05}s` } as React.CSSProperties
          }
        >
          {char}
        </span>
      ))}
      {/* Add a space after each word to ensure proper spacing between words */}
      {wordIndex < text.split(' ').length - 1 && ' '}
    </span>
  ));

  return (
    <div id="home-global-impact">
      <div className="w-full overflow-visible title">
        {t('page.home.global_impact.title')
          .split(' ')
          .map((word: string, index: number) => (
            <div key={index}>
              <div key={index} className="title-word text-magnify">
                {word}
                <br />
              </div>
            </div>
          ))}
      </div>
      {/* <p>{words}</p> */}
      {/* <p>{t('page.home.global_impact.description_1')}</p> */}
      {/* <p>{t('page.home.global_impact.description_2')}</p> */}
      {/* <p className="w-1/2 break-words">
        {text.split(' ').map((word: string, index: number) => (
          <span
            className="intro-word pulsate"
            key="{index}"
            onMouseEnter={playElevateSound}
          >
            {word.split('').map((char: string, index: number) => (
              <Letter
                // className="character-flash"
                key={index}
                // onMouseEnter={playElevateSound}
              >
                {char}
              </Letter>
            ))}
            &nbsp;
          </span>
        ))}
      </p> */}
      <p className="w-half break-words pb-50">
        {text.split(' ').map((word: any, wordIndex: any) => (
          <span
            className="intro-word pulsate"
            key={wordIndex} // Correctly assigning React keys
            onMouseEnter={playElevateSound}
          >
            {word.split('').map((char: any, charIndex: any) => (
              <span
                className="character-flash"
                key={`${wordIndex}-${charIndex}`} // Unique key for each character
                onMouseEnter={playElevateSound}
              >
                {char}
              </span>
            ))}
            {wordIndex < text.split(' ').length - 1 && '\u00A0'}
          </span>
        ))}
      </p>

      <span className="space"></span>
      <div className="flex justify-center" ref={videoRef} onMouseEnter={playOrbSound}>
        <video autoPlay muted loop playsInline>
          <source src={Orb} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default GlobalImpact;
