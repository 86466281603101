import { useContext, useState, useEffect, Fragment } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../../contexts/AppContext';
import AppButton from '../../../../components/AppButton';
import { ArrowRight } from '../../../../lib/icons';
import Logo from '../../../../assets/images/logo.svg';
import BackgroundVideo from '../../../../assets/videos/bg_hero.mp4';
import titleHoverSound from '../../../../assets/sounds/servicePhrase.wav';
import './intro.style.css';

const Intro = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setOnContactDlg } = useContext(AppContext);
  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const enableInteraction = () => setHasInteracted(true);
    window.addEventListener('click', enableInteraction);

    return () => {
      window.removeEventListener('click', enableInteraction);
    };
  }, []);

  const playSound = () => {
    const audio = new Audio(titleHoverSound);
    if (hasInteracted) {
      audio.play().catch((error) => console.error('Error playing sound:', error));
    }
  };

  const handleClick = () => {
    navigate('/services');
  };

  const magnifyLetters = (text: string) => {
    return (
      <div className="breakable-text-container">
        {text.split(' ').map((word, wordIndex) => (
          // Create a span for each word
          <span key={wordIndex} className="word">
            {word.split('').map((char, charIndex) => (
              // Create a span for each character within the word
              <span
                key={charIndex}
                className="letter magnify-on-hover"
                onMouseEnter={() => playSound()}
              >
                {char}
              </span>
            ))}
            {/* Add a non-breaking space after each word */}
            {wordIndex < text.split(' ').length - 1 && '\u00A0'}
          </span>
        ))}
      </div>
    );
  };

  return (
    <div id="home-intro">
      <video className="background-video" autoPlay loop muted playsInline>
        <source src={BackgroundVideo} type="video/mp4" />
      </video>
      <div className="section-left image-container">
        <img src={Logo} className="logo" alt="sokai" />
        {/* <div className="menu">
          <Link to="/services"> {t('control.services')}</Link>
          <span
            aria-hidden
            className="app-btn"
            onClick={() => {
              setOnContactDlg(true);
            }}
          >
            {t('control.contact')}
          </span>
        </div> */}
      </div>
      <div className="section-right">
        <div className="wrapper hover:bg-gray-100">
          <h3>{magnifyLetters(t('page.home.intro.title'))}</h3>
          <p>{magnifyLetters(t('page.home.intro.description'))}</p>
          <AppButton
            label={t('control.checkout')}
            onClick={handleClick}
            icon={<ArrowRight />}
          />
        </div>
      </div>
    </div>
  );
};

export default Intro;
