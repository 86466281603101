import { ChangeEvent } from 'react';
import './index.css';

interface IAppInputProps {
  type: string;
  value: string;
  placeholder: string;
  error: null | string;
  required?: boolean;
  setValue: (val: string) => void;
}

const AppInput = ({
  type,
  value,
  placeholder,
  setValue,
  required,
  error,
}: IAppInputProps) => {
  return (
    <div className="app-input">
      <input
        className={`app-input-container ${error ? `border-warn` : `border-normal`}`}
        type={type}
        value={value}
        placeholder={placeholder}
        required={required}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value);
        }}
      />
      {error && <span className="app-input-error">{error}</span>}
    </div>
  );
};

export default AppInput;
