import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppPage from '../layouts/AppPage';
import Home from '../pages/home/home.page';
import Services from '../pages/services/services.page';
import Team from '../pages/team/team.page';
import Portfolio from '../pages/portfolio/portfolio.page';
import Decks from '../pages/decks/decks.page';
import Venues from '../pages/venues/venues.page';
import Properties from '../pages/properties/properties.page';
import NFTSoundDesign from '../pages/services/services/nftSoundDesign';
import InfluencerMarketing from '../pages/services/services/influencerMarketing';
import DJBooking from '../pages/services/services/djBooking';
import SocialMedia from '../pages/services/services/socialMedia';
import SoundDesign from '../pages/services/services/soundDesign';
import RealEstate from '../pages/services/services/realEstateExpertise';
import VideoProduction from '../pages/services/services/videoProduction';
import DigitalMarketing from '../pages/services/services/digitalMarketing';
import BrandNarrative from '../pages/services/services/brandNarrative';
import VisualIdentity from '../pages/services/services/visualIdentity';
import EventProduction from '../pages/services/services/eventProduction';
import MusicProduction from '../pages/services/services/musicProduction';
import MetaverseAndAI from '../pages/services/services/metaverseAndAI';
const Router = () => {
  return (
    <BrowserRouter>
      <AppPage>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/services" Component={Services} />
          <Route path="/team" Component={Team} />
          <Route path="/portfolio" Component={Portfolio} />
          <Route path="/decks" Component={Decks} />
          <Route path="/events" Component={Decks} />
          <Route path="/venues" Component={Venues} />
          <Route path="/music" Component={Decks} />
          <Route path="/properties" Component={Properties} />
          <Route path="/nftSoundDesign" Component={NFTSoundDesign} />
          <Route path="/influencerMarketing" Component={InfluencerMarketing} />
          <Route path="/djBooking" Component={DJBooking} />
          <Route path="/socialMedia" Component={SocialMedia} />
          <Route path="/soundDesign" Component={SoundDesign} />
          <Route path="/realEstateExpertise" Component={RealEstate} />
          <Route path="/videoProduction" Component={VideoProduction} />
          <Route path="/brandNarrative" Component={BrandNarrative} />
          <Route path="/visualIdentity" Component={VisualIdentity} />
          <Route path="/eventProduction" Component={EventProduction} />
          <Route path="/musicProduction" Component={MusicProduction} />
          <Route path="/metaverseAndAI" Component={MetaverseAndAI} />
          <Route path="/digitalMarketing" Component={DigitalMarketing} />
        </Routes>
      </AppPage>
    </BrowserRouter>
  );
};

export default Router;
