import { useTranslation } from 'react-i18next';
import ExcluBG from '../../../../assets/images/home/exclusive/bg.jpg';
import VideoExclusive from '../../../../assets/videos/bg_exclusive.mp4';

import './Exclusive.style.css';
// import MagnifyingGlassComponent from '../../../../components/MagnifyingGlass/MagnifyingGlass';
const Exclusive = () => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <div id="home-exclusive">
      <div className="wrapper grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1">
        <div className="left-side">
          <h2 className="text-center">{t('page.home.exclusive.title')}</h2>
          <div className="categories"></div>
          <div className="text-left font-unbounded bold text-lg mb-20">
            {t('page.home.exclusive.description_1')}
          </div>
          <div id="VideoExclusive" className="sm:visible md:hidden">
            <video autoPlay loop muted playsInline poster={ExcluBG}>
              <source src={VideoExclusive} type="video/mp4" />
            </video>
          </div>
          <div className="text-left font-unbounded bold text-lg mt-20 mb-20">
            {t('page.home.exclusive.description_2')}
          </div>
          <ul>
            <li>{t('page.home.exclusive.item_1')}</li>
            <li>{t('page.home.exclusive.item_2')}</li>
            <li>{t('page.home.exclusive.item_3')}</li>
            <li>{t('page.home.exclusive.item_4')}</li>
          </ul>
        </div>
        <div className="right-side">
          <video autoPlay loop muted playsInline poster={ExcluBG}>
            <source src={VideoExclusive} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default Exclusive;
