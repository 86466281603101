import { ReactElement } from 'react';

import AppHeader from '../../containers/AppHeader';
import AppFooter from '../../containers/AppFooter';
import AppContact from '../../containers/AppContact';

interface IAppPageProps {
  children: ReactElement;
}

const AppPage = ({ children }: IAppPageProps) => {
  return (
    <div id="app-page">
      <AppHeader />
      {children}
      <AppContact />
      <AppFooter />
    </div>
  );
};

export default AppPage;
