import { AppMessages } from './constants';

export const FORM_VALIDATION = {
  email: (val: string | null) => {
    if (!val) {
      return AppMessages.control.required;
    }

    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(val)
      ? null
      : 'Invalid email, please check your input.';
  },

  message: (val: string | null, maxLength: number) => {
    if (!val) {
      return AppMessages.control.required;
    }

    if (val.length > maxLength) {
      return AppMessages.control.contactMessage;
    }

    return null;
  },

  required: (val: string | null) => {
    if (!val) {
      return AppMessages.control.required;
    }

    return null;
  },
};
