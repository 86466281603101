import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Mousewheel } from 'swiper';
// import AppButton from '../../../../components/AppButton';
// import { services } from '../../../../lib/constants';
// import { ArrowRight } from '../../../../lib/icons';
// import ServicesBG from '../../../../assets/images/background/bg-services-bottom.jpg';
import FeaturedServices from './Services.featured.container';

// import 'swiper/swiper-bundle.css';
import './Services.style.css';
import ServicesMatrix from '../../../services/containers/ServicesMatrix/ServicesMatrix.container';

const Services = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);

  const handleClick = () => {
    navigate('/services#top');
  };

  return (
    <Fragment>
      <div id="home-services">
        <FeaturedServices />
      </div>
      {/* <img className="service-bottom" src={ServicesBG} alt="" /> */}
    </Fragment>
  );
};

export default Services;
