import { useState } from 'react';
import { ILang } from '../../lib/interfaces';
import './index.css';

export interface IAppDropdownProps {
  values: ILang[];
  selectedItem: ILang;
  selectItem: (val: ILang) => void;
}

const AppDropdown = ({ values, selectItem, selectedItem }: IAppDropdownProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <div id="app-dropdown" className="app-btn">
      <span
        aria-hidden
        className="app-dropdown-btn app-btn"
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        {selectedItem.label}
        <svg
          className="-mr-1 h-5 w-5 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </span>
      <div className={`app-dropdown-menu ${isOpen ? 'show-content' : 'hidden-content'}`}>
        {values.map((item: ILang) => (
          <span
            key={`lang-item-${item.code}`}
            aria-hidden
            className="app-dropdown-menu-item app-btn"
            onClick={() => {
              selectItem(item);
              setOpen(false);
            }}
          >
            {item.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default AppDropdown;
