import { useEffect, useState } from 'react';

import portfolioHoverSound from '../../assets/sounds/portfolioHover.wav';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import PortfolioPieceTOCCard from './containers/PortfolioPieceTOCCard/PortfolioPieceTOCCard.container';
import { IPortfolioPieceTOC } from './interfaces';
import { portfolioPieceTOC } from './constants';

const Decks = () => {
  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const enableInteraction = () => setHasInteracted(true);
    window.addEventListener('click', enableInteraction);

    return () => {
      window.removeEventListener('click', enableInteraction);
    };
  }, []);

  const playSound = () => {
    const audio = new Audio(portfolioHoverSound);
    if (hasInteracted) {
      audio.play().catch((error) => console.error('Error playing sound:', error));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="portfolio-page" className="pt-20">
      <div className="portfolio-toc-section grid lg:grid-cols-4 sm:grid-cols-1 xs:grid-cols-1 md:grid-cols-2 gap-0">
        {portfolioPieceTOC.map((item: IPortfolioPieceTOC, index: number) => (
          <PortfolioPieceTOCCard
            key={`portfolio-toc-card-${index}`}
            title={item.title}
            tinyImage={item.tinyImage}
            initialImage={item.initialImage}
            image={item.image}
            link={item.link}
            playSound={playSound}
          />
        ))}
      </div>
    </div>
  );
};

export default Decks;
