import { t } from 'i18next';
import './services-pages.css';
import { useTranslation } from 'react-i18next';

const SocialMedia = () => {
  const { t } = useTranslation();
  return (
    <div id="services-social-media-page-layer1">
      <div id="services-page-layer2">
        <div id="services-page-layer3">
          <div className="wrapper">
            <div style={{ paddingLeft: '5%', paddingTop: '24%' }}>
              <div className="title">
                <h2>{t('page.services.social_media.title')}</h2>
              </div>
              <div className="subtitle">
                {t('page.services.social_media.description')}
              </div>
              <div className="services-title">{t('page.services.our_work_included')}</div>
              <div className="space-y-4 pt-5">
                {/* line1 */}
                <div className="flex space-x-4">
                  <div className="services-bar">
                    {t('page.services.music_production')}
                  </div>
                  <div className="services-bar">
                    {t('page.services.artist_management')}
                  </div>
                </div>
                {/* line2 */}
                <div className="flex space-x-4">
                  <div className="services-bar">{t('page.services.music_promotion')}</div>
                  <div className="services-bar">
                    {t('page.services.content_creation')}
                  </div>
                </div>
                {/* line3 */}
                <div className="flex">
                  <div className="services-bar">
                    {t('page.services.event_organization')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
