import { useEffect, useState } from 'react';
import Intro from './containers/Intro/Intro.container';
import GlobalImpact from './containers/GlobalImpact/GlobalImpact.container';
import Services from './containers/Services/Services.container';
import Projects from './containers/Projects/Projects.container';
import Portfolio from './containers/Portfolio/Portfolio.container';
import Why from './containers/Why/Why.container';
import Book from './containers/Book/Book.container';
import Exclusive from './containers/Exclusive/Exclusive.container';
import Partners from './containers/Partners/Partners.container';
import PartnerUp from './containers/PartnerUp/PartnerUp.container';
// import Aurora from '../../assets/videos/Aurora.mp4';
// import LightsStreamOutAndBack from '../../assets/videos/Light streams out and back.mp4';
// import LightsStreamUpAndRight from '../../assets/videos/Light streams up.mp4';
// import LightStreamsFormATunnel from '../../assets/videos/Light streams form a tunnel.mp4';
// import LightRetracting from '../../assets/videos/Light retracting.mp4';
// import NervousLightRetracting from '../../assets/videos/Nervous light retracting.mp4';
import SingleBeamOfLight from '../../assets/videos/Single beam of light.mp4';
import lightMovement from '../../assets/sounds/lightMovementCadence.wav';
import lightClap from '../../assets/sounds/lightClap.mp3';
import flipSound from '../../assets/sounds/lightClap.mp3';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const enableInteraction = () => setHasInteracted(true);
    window.addEventListener('click', enableInteraction);

    return () => {
      window.removeEventListener('click', enableInteraction);
    };
  }, []);

  const playLightClapSound = () => {
    const audio = new Audio(lightClap);
    if (hasInteracted) {
      audio.play().catch((error) => console.error('Error playing sound:', error));
    }
  };

  const playLightMovementSound = () => {
    const audio = new Audio(lightMovement);
    audio.volume = 0.5; // Set volume to 50%
    if (hasInteracted) {
      audio.play().catch((error) => console.error('Error playing sound:', error));
    }
  };

  const playProjectFlipSound = () => {
    const audio = new Audio(flipSound);
    audio.volume = 0.5; // Set volume to 50%
    if (hasInteracted) {
      audio.play().catch((error) => console.error('Error playing sound:', error));
    }
  };

  return (
    <div id="home-page">
      <Intro />
      <GlobalImpact />
      <Services />
      <Projects />
      <div className="video-container">
        <div className="top-fade"></div>
        <video autoPlay muted loop playsInline onMouseEnter={playLightClapSound}>
          <source src={SingleBeamOfLight} type="video/mp4" />
        </video>
        <div className="video-overlay"></div>
        <div className="bottom-fade"></div>
      </div>
      <Portfolio />
      {/* <div className="video-container">
        <div className="top-fade"></div>
        <video
          className="video-container mb-100"
          autoPlay
          loop
          muted
          poster={PortfolioBG}
        >
          <source src={LightsStreamOutAndBack} type="video/mp4" />
        </video>
        <div className="bottom-fade"></div>
      </div> */}
      <Why />
      {/* <div className="video-container">
        <video
          className="video-container mb-1000"
          autoPlay
          loop
          muted
          poster={PortfolioBG}
        >
          <source src={LightStreamsFormATunnel} type="video/mp4" />
        </video>
        <div className="bottom-fade"></div>
      </div> */}
      <Book />
      <Exclusive />
      <Partners />
      <PartnerUp />
    </div>
  );
};

export default Home;
