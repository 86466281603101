import { ChangeEvent } from 'react';
import { MESSAGE_MAX_LENGTH } from '../../lib/constants';
import './index.css';

interface IAppTextareaProps {
  value: string;
  placeholder: string;
  required?: boolean;
  setValue: (val: string) => void;
  error: null | string;
}

const AppTextarea = ({
  value,
  placeholder,
  setValue,
  required,
  error,
}: IAppTextareaProps) => {
  return (
    <div className="app-textarea">
      <textarea
        className={`app-textarea-container ${error ? `border-warn` : `border-normal`}`}
        value={value}
        placeholder={placeholder}
        required={required}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
          setValue(e.target.value);
        }}
      />
      {error && (
        <div className="app-textarea-error">
          <span>{error}</span>
          <span>
            {value.length} / {MESSAGE_MAX_LENGTH}
          </span>
        </div>
      )}
    </div>
  );
};

export default AppTextarea;
