import { useState } from 'react';
import { useSwiper, Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import { EffectFade, Autoplay } from 'swiper';
import Reason from './components/Reason';
import { reasons } from '../../constants';
import { IReason } from '../../interfaces';
import 'swiper/swiper-bundle.css';
import './Why.style.css';

const Why = () => {
  const [active, setActive] = useState(0);
  const { t } = useTranslation();

  const Bullets = () => {
    const swiper = useSwiper();

    return (
      <ul className="bullets">
        {Object.values(reasons).map((reason: IReason, key: number) => (
          <li
            aria-hidden
            className={`app-btn ${key === active ? 'active' : ''}`}
            onClick={() => swiper.slideNext()}
            key={key}
          >
            |
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div id="home-why">
      <div className="wrapper">
        <h2 className="mb-[25px]">{t('page.home.why.title')}</h2>
        <div className="slider">
          <Swiper
            onSlideChange={(slide: any) => setActive(slide.realIndex)}
            slidesPerView={1}
            spaceBetween={30}
            effect={'fade'}
            modules={[EffectFade, Autoplay]}
            grabCursor={true}
            speed={1000}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
          >
            <Bullets />
            {Object.values(reasons).map((reason: IReason, key: number) => (
              <SwiperSlide key={key}>
                <Reason
                  image={reason.image}
                  title={reason.title}
                  description={reason.description}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Why;
