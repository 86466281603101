import { useTranslation } from 'react-i18next';
import Logo from '../../assets/images/logo.svg';
import { useLocation } from 'react-router-dom';

import './index.css';

const AppFooter = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPage = location.pathname.split('/');
  return (
    <>
      {/* Remove footer for services pages */}
      {currentPage[1] !== 'influencerMarketing' &&
        currentPage[1] !== 'nftSoundDesign' &&
        currentPage[1] !== 'soundDesign' &&
        currentPage[1] !== 'djBooking' &&
        currentPage[1] !== 'realEstateExpertise' &&
        currentPage[1] !== 'socialMedia' &&
        currentPage[1] !== 'videoProduction' &&
        currentPage[1] !== 'brandNarrative' &&
        currentPage[1] !== 'visualIdentity' &&
        currentPage[1] !== 'eventProduction' &&
        currentPage[1] !== 'portfolio' &&
        currentPage[1] !== 'services' &&
        currentPage[1] !== 'metaverseAndAI' && (
          <footer id="app-footer">
            <div className="wrapper">
              <div className="left-side">
                <h4>{t('container.app_footer.title')}</h4>
                <div className="flex justify-center items-center gap-4 text-white p-4">
                  <a href="mailto:j@sokai.media">meet@sokai.media</a>
                  <a
                    href="https://wa.me/19548823115"
                    className="hover:text-gray-400"
                    target="_blank"
                    aria-label="WhatsApp"
                    rel="noreferrer"
                  >
                    <i className="fab fa-whatsapp fa-lg"></i>
                  </a>
                  <a
                    href="https://facebook.com/yourpage"
                    className="hover:text-gray-400"
                    target="_blank"
                    aria-label="Facebook"
                    rel="noreferrer"
                  >
                    <i className="fab fa-facebook-f fa-lg"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/sokail-media-agency-a12ab7298"
                    className="hover:text-gray-400"
                    target="_blank"
                    aria-label="LinkedIn"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin-in fa-lg"></i>
                  </a>
                  <a
                    href="https://instagram.com/sokail__"
                    className="hover:text-gray-400"
                    target="_blank"
                    aria-label="Instagram"
                    rel="noreferrer"
                  >
                    <i className="fab fa-instagram fa-lg"></i>
                  </a>
                </div>
              </div>

              <div className="right-side">
                <p>{t('container.app_footer.cities')}</p>
                <img src={Logo} className="logo" alt={'sokai'} />
              </div>
            </div>
          </footer>
        )}
    </>
  );
};

export default AppFooter;
