import { useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import Router from './routes';
import AppContext, { IAppContext } from './contexts/AppContext';
import i18n from './i18n';

const App = () => {
  const [onContactDlg, setOnContactDlg] = useState<boolean>(false);
  const contextValues: IAppContext = {
    onContactDlg,
    setOnContactDlg,
  };

  return (
    <I18nextProvider i18n={i18n}>
      <AppContext.Provider value={contextValues}>
        <Router />
      </AppContext.Provider>
    </I18nextProvider>
  );
};

export default App;
