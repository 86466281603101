import { IService, IServiceTOC } from './interfaces';

import EventImg2 from '../../assets/images/service/event-2.gif';
import EventImg5 from '../../assets/images/service/event-5.gif';
import EventImg10 from '../../assets/images/service/event-10.gif';
import EventImg25 from '../../assets/images/service/event-25.gif';
import EventImg40 from '../../assets/images/service/event-40.gif';
import EventImg from '../../assets/images/service/event.gif';

import SoundImg2 from '../../assets/images/service/sound-2.gif';
import SoundImg5 from '../../assets/images/service/sound-5.gif';
import SoundImg10 from '../../assets/images/service/sound-10.gif';
import SoundImg25 from '../../assets/images/service/sound-25.gif';
import SoundImg40 from '../../assets/images/service/sound-40.gif';
import SoundImg from '../../assets/images/service/sound.gif';

import MetaverseImg2 from '../../assets/images/service/metaverse-2.gif';
import MetaverseImg5 from '../../assets/images/service/metaverse-5.gif';
import MetaverseImg10 from '../../assets/images/service/metaverse-10.gif';
import MetaverseImg25 from '../../assets/images/service/metaverse-25.gif';
import MetaverseImg40 from '../../assets/images/service/metaverse-40.gif';
import MetaverseImg from '../../assets/images/service/metaverse.gif';

import MarketingImg2 from '../../assets/images/service/marketing-2.gif';
import MarketingImg5 from '../../assets/images/service/marketing-5.gif';
import MarketingImg10 from '../../assets/images/service/marketing-10.gif';
import MarketingImg25 from '../../assets/images/service/marketing-25.gif';
import MarketingImg40 from '../../assets/images/service/marketing-40.gif';
import MarketingImg from '../../assets/images/service/marketing.gif';

import BrandImg2 from '../../assets/images/service/brand-2.gif';
import BrandImg5 from '../../assets/images/service/brand-5.gif';
import BrandImg10 from '../../assets/images/service/brand-10.gif';
import BrandImg25 from '../../assets/images/service/brand-25.gif';
import BrandImg40 from '../../assets/images/service/brand-40.gif';
import BrandImg from '../../assets/images/service/brand.gif';

import DJBooking2 from '../../assets/images/toc/dj-booking-2.gif';
import DJBooking5 from '../../assets/images/toc/dj-booking-5.gif';
import DJBooking10 from '../../assets/images/toc/dj-booking-10.gif';
import DJBooking25 from '../../assets/images/toc/dj-booking-25.gif';
import DJBooking40 from '../../assets/images/toc/dj-booking-40.gif';
import DJBooking from '../../assets/images/toc/dj-booking.gif';

import Metaverse2 from '../../assets/images/toc/metaverse-2.gif';
import Metaverse5 from '../../assets/images/toc/metaverse-5.gif';
import Metaverse10 from '../../assets/images/toc/metaverse-10.gif';
import Metaverse25 from '../../assets/images/toc/metaverse-25.gif';
import Metaverse40 from '../../assets/images/toc/metaverse-40.gif';
import Metaverse from '../../assets/images/toc/metaverse.gif';

import EventProduction2 from '../../assets/images/toc/event-production-2.gif';
import EventProduction5 from '../../assets/images/toc/event-production-5.gif';
import EventProduction10 from '../../assets/images/toc/event-production-10.gif';
import EventProduction25 from '../../assets/images/toc/event-production-25.gif';
import EventProduction40 from '../../assets/images/toc/event-production-40.gif';
import EventProduction from '../../assets/images/toc/event-production.gif';

import SoundDesign2 from '../../assets/images/toc/sound-design-2.gif';
import SoundDesign5 from '../../assets/images/toc/sound-design-5.gif';
import SoundDesign10 from '../../assets/images/toc/sound-design-10.gif';
import SoundDesign25 from '../../assets/images/toc/sound-design-25.gif';
import SoundDesign40 from '../../assets/images/toc/sound-design-40.gif';
import SoundDesign from '../../assets/images/toc/sound-design.gif';

import MusicProduction2 from '../../assets/images/toc/music-production-2.gif';
import MusicProduction5 from '../../assets/images/toc/music-production-5.gif';
import MusicProduction10 from '../../assets/images/toc/music-production-10.gif';
import MusicProduction25 from '../../assets/images/toc/music-production-25.gif';
import MusicProduction40 from '../../assets/images/toc/music-production-40.gif';
import MusicProduction from '../../assets/images/toc/music-production.gif';

import AvatarGeneration2 from '../../assets/images/toc/avatar-generation-2.gif';
import AvatarGeneration5 from '../../assets/images/toc/avatar-generation-5.gif';
import AvatarGeneration10 from '../../assets/images/toc/avatar-generation-10.gif';
import AvatarGeneration25 from '../../assets/images/toc/avatar-generation-25.gif';
import AvatarGeneration40 from '../../assets/images/toc/avatar-generation-40.gif';
import AvatarGeneration from '../../assets/images/toc/avatar-generation.gif';

import DigitalMarketing2 from '../../assets/images/toc/digital-marketing-2.gif';
import DigitalMarketing5 from '../../assets/images/toc/digital-marketing-5.gif';
import DigitalMarketing10 from '../../assets/images/toc/digital-marketing-10.gif';
import DigitalMarketing25 from '../../assets/images/toc/digital-marketing-25.gif';
import DigitalMarketing40 from '../../assets/images/toc/digital-marketing-40.gif';
import DigitalMarketing from '../../assets/images/toc/digital-marketing.gif';

import InfluencerMarketing2 from '../../assets/images/toc/influencer-marketing-2.gif';
import InfluencerMarketing5 from '../../assets/images/toc/influencer-marketing-5.gif';
import InfluencerMarketing10 from '../../assets/images/toc/influencer-marketing-10.gif';
import InfluencerMarketing25 from '../../assets/images/toc/influencer-marketing-25.gif';
import InfluencerMarketing40 from '../../assets/images/toc/influencer-marketing-40.gif';
import InfluencerMarketing from '../../assets/images/toc/influencer-marketing.gif';

import NFTSoundDesign2 from '../../assets/images/toc/nft-sound-design-2.gif';
import NFTSoundDesign5 from '../../assets/images/toc/nft-sound-design-5.gif';
import NFTSoundDesign10 from '../../assets/images/toc/nft-sound-design-10.gif';
import NFTSoundDesign25 from '../../assets/images/toc/nft-sound-design-25.gif';
import NFTSoundDesign40 from '../../assets/images/toc/nft-sound-design-40.gif';
import NFTSoundDesign from '../../assets/images/toc/nft-sound-design.gif';

import VisualIdentity2 from '../../assets/images/toc/visual-identity-2.gif';
import VisualIdentity5 from '../../assets/images/toc/visual-identity-5.gif';
import VisualIdentity10 from '../../assets/images/toc/visual-identity-10.gif';
import VisualIdentity25 from '../../assets/images/toc/visual-identity-25.gif';
import VisualIdentity40 from '../../assets/images/toc/visual-identity-40.gif';
import VisualIdentity from '../../assets/images/toc/visual-identity.gif';

import BrandNarrative2 from '../../assets/images/toc/brand-narrative-2.gif';
import BrandNarrative5 from '../../assets/images/toc/brand-narrative-5.gif';
import BrandNarrative10 from '../../assets/images/toc/brand-narrative-10.gif';
import BrandNarrative25 from '../../assets/images/toc/brand-narrative-25.gif';
import BrandNarrative40 from '../../assets/images/toc/brand-narrative-40.gif';
import BrandNarrative from '../../assets/images/toc/brand-narrative.gif';

import SocialMedia2 from '../../assets/images/toc/social-media-2.gif';
import SocialMedia5 from '../../assets/images/toc/social-media-5.gif';
import SocialMedia10 from '../../assets/images/toc/social-media-10.gif';
import SocialMedia25 from '../../assets/images/toc/social-media-25.gif';
import SocialMedia40 from '../../assets/images/toc/social-media-40.gif';
import SocialMedia from '../../assets/images/toc/social-media.gif';

import ArchitecturalRendering2 from '../../assets/images/toc/architectural-rendering-2.gif';
import ArchitecturalRendering5 from '../../assets/images/toc/architectural-rendering-5.gif';
import ArchitecturalRendering10 from '../../assets/images/toc/architectural-rendering-10.gif';
import ArchitecturalRendering25 from '../../assets/images/toc/architectural-rendering-25.gif';
import ArchitecturalRendering40 from '../../assets/images/toc/architectural-rendering-40.gif';
import ArchitecturalRendering from '../../assets/images/toc/architectural-rendering.gif';

import OnlineIdentity2 from '../../assets/images/toc/online-identity-2.gif';
import OnlineIdentity5 from '../../assets/images/toc/online-identity-5.gif';
import OnlineIdentity10 from '../../assets/images/toc/online-identity-10.gif';
import OnlineIdentity25 from '../../assets/images/toc/online-identity-25.gif';
import OnlineIdentity40 from '../../assets/images/toc/online-identity-40.gif';
import OnlineIdentity from '../../assets/images/toc/online-identity.gif';

import HomeStaging2 from '../../assets/images/toc/home-staging-2.gif';
import HomeStaging5 from '../../assets/images/toc/home-staging-5.gif';
import HomeStaging10 from '../../assets/images/toc/home-staging-10.gif';
import HomeStaging25 from '../../assets/images/toc/home-staging-25.gif';
import HomeStaging40 from '../../assets/images/toc/home-staging-40.gif';
import HomeStaging from '../../assets/images/toc/home-staging.gif';

import Applications2 from '../../assets/images/toc/applications-2.gif';
import Applications5 from '../../assets/images/toc/applications-5.gif';
import Applications10 from '../../assets/images/toc/applications-10.gif';
import Applications25 from '../../assets/images/toc/applications-25.gif';
import Applications40 from '../../assets/images/toc/applications-40.gif';
import Applications from '../../assets/images/toc/applications.gif';

import Token2 from '../../assets/images/toc/token-2.gif';
import Token5 from '../../assets/images/toc/token-5.gif';
import Token10 from '../../assets/images/toc/token-10.gif';
import Token25 from '../../assets/images/toc/token-25.gif';
import Token40 from '../../assets/images/toc/token-40.gif';
import Token from '../../assets/images/toc/token.gif';

import Rendering2 from '../../assets/images/toc/rendering-2.gif';
import Rendering5 from '../../assets/images/toc/rendering-5.gif';
import Rendering10 from '../../assets/images/toc/rendering-10.gif';
import Rendering25 from '../../assets/images/toc/rendering-25.gif';
import Rendering40 from '../../assets/images/toc/rendering-40.gif';
import Rendering from '../../assets/images/toc/rendering.gif';

import VideoProduction2 from '../../assets/images/toc/video-production-2.gif';
import VideoProduction5 from '../../assets/images/toc/video-production-5.gif';
import VideoProduction10 from '../../assets/images/toc/video-production-10.gif';
import VideoProduction25 from '../../assets/images/toc/video-production-25.gif';
import VideoProduction40 from '../../assets/images/toc/video-production-40.gif';
import VideoProduction from '../../assets/images/toc/video-production.gif';

import RealEstate2 from '../../assets/images/toc/real-estate-2.gif';
import RealEstate5 from '../../assets/images/toc/real-estate-5.gif';
import RealEstate10 from '../../assets/images/toc/real-estate-10.gif';
import RealEstate25 from '../../assets/images/toc/real-estate-25.gif';
import RealEstate40 from '../../assets/images/toc/real-estate-40.gif';
import RealEstate from '../../assets/images/toc/real-estate.gif';

// onths

export const services: IService[] = [
  {
    title: 'Full service event production',
    description: `We are a well-established industry leader in experience design, event organization and marketing. Having strong relationships with world’AWs best bands and DJs, and access to VIP locations, we are able to <b>create the most exclusive and bold experiences for our clients.</b>`,
    image: EventImg,
    items: ['Venues', 'DJ Booking', 'A/V equipment', 'Creative concepts'],
  },
  {
    title: 'Sound & Visual',
    description: `We're passionately dedicated to bringing your ideas to life. Our expertise shines in <b>crafting exceptional soundscapes, compelling videos, and visionary architectural concepts,</b> ensuring your projects are marked by excellence and creativity.`,
    image: SoundImg,
    items: [
      'Video production',
      'Music production',
      'Sound design',
      'Architectural design',
    ],
  },
  {
    title: 'Metaverse & AI',
    description: `Living in a digital world is a whole new realm, and existing between reality and digital world requires to have a human representation in both. Our team uses <b>latest cutting-edge technology for the digitalization to improve your online presence, foster innovation and enhance user experience.</b>`,
    image: MetaverseImg,
    items: ['Metaverse production', 'Sound design for NFTs', 'AI avatar generation'],
  },
  {
    title: 'Brand development',
    description: `<b>We help brands be their most inspiring selves. </b>Own their quirks - their edge - their culture. Help them be confidently attractive. We can do it all: from logo creation to market placement, with a special focus on the fashion industry.`,
    image: BrandImg,
    items: ['Visual identity', 'Brand narrative'],
  },
  {
    title: 'Online Marketing',
    description: `We go beyond of what is possible in the digital realm. By delivering creative and strategic solutions to any digital problem, we can only say:
                            </br></br>
                          Whatever it is, we can make it happen.`,
    image: MarketingImg,
    items: ['Digital marketing', 'Social media', 'Influencer marketing'],
  },
];

export const servicesTOC: IServiceTOC[] = [
  {
    title: 'Full Service Event Production',
    image2: EventProduction2,
    image5: EventProduction5,
    image10: EventProduction10,
    image25: EventProduction25,
    image40: EventProduction40,
    image: EventProduction,
    serviceUrl: 'eventProduction',
  },
  {
    title: 'DJ Booking',
    image2: DJBooking2,
    image5: DJBooking5,
    image10: DJBooking10,
    image25: DJBooking25,
    image40: DJBooking40,
    image: DJBooking,
    serviceUrl: 'djBooking',
  },
  {
    title: 'Metaverse Production',
    image2: Metaverse2,
    image5: Metaverse5,
    image10: Metaverse10,
    image25: Metaverse25,
    image40: Metaverse40,
    image: Metaverse,
    serviceUrl: 'metaverseAndAI',
  },
  {
    title: 'Sound Design',
    image2: SoundDesign2,
    image5: SoundDesign5,
    image10: SoundDesign10,
    image25: SoundDesign25,
    image40: SoundDesign40,
    image: SoundDesign,
    serviceUrl: 'soundDesign',
  },
  {
    title: 'Music Production',
    image2: MusicProduction2,
    image5: MusicProduction5,
    image10: MusicProduction10,
    image25: MusicProduction25,
    image40: MusicProduction40,
    image: MusicProduction,
    serviceUrl: 'musicProduction',
  },
  {
    title: 'AI Avatar Generation',
    image2: AvatarGeneration2,
    image5: AvatarGeneration5,
    image10: AvatarGeneration10,
    image25: AvatarGeneration25,
    image40: AvatarGeneration40,
    image: AvatarGeneration,
    serviceUrl: 'avatarGeneration',
  },
  {
    title: 'Digital Marketing',
    image2: DigitalMarketing2,
    image5: DigitalMarketing5,
    image10: DigitalMarketing10,
    image25: DigitalMarketing25,
    image40: DigitalMarketing40,
    image: DigitalMarketing,
    serviceUrl: 'digitalMarketing',
  },
  {
    title: 'Influencer Marketing',
    image2: InfluencerMarketing2,
    image5: InfluencerMarketing5,
    image10: InfluencerMarketing10,
    image25: InfluencerMarketing25,
    image40: InfluencerMarketing40,
    image: InfluencerMarketing,
    serviceUrl: 'influencerMarketing',
  },
  {
    title: 'NFT Sound Design',
    image2: NFTSoundDesign2,
    image5: NFTSoundDesign5,
    image10: NFTSoundDesign10,
    image25: NFTSoundDesign25,
    image40: NFTSoundDesign40,
    image: NFTSoundDesign,
    serviceUrl: 'nftSoundDesign',
  },
  {
    title: 'Visual Identity',
    image2: VisualIdentity2,
    image5: VisualIdentity5,
    image10: VisualIdentity10,
    image25: VisualIdentity25,
    image40: VisualIdentity40,
    image: VisualIdentity,
    serviceUrl: 'visualIdentity',
  },
  {
    title: 'Brand Narrative',
    image2: BrandNarrative2,
    image5: BrandNarrative5,
    image10: BrandNarrative10,
    image25: BrandNarrative25,
    image40: BrandNarrative40,
    image: BrandNarrative,
    serviceUrl: 'brandNarrative',
  },
  {
    title: 'Social Media',
    image2: SocialMedia2,
    image5: SocialMedia5,
    image10: SocialMedia10,
    image25: SocialMedia25,
    image40: SocialMedia40,
    image: SocialMedia,
    serviceUrl: 'socialMedia',
  },
  {
    title: 'Architectural Design',
    image2: ArchitecturalRendering2,
    image5: ArchitecturalRendering5,
    image10: ArchitecturalRendering10,
    image25: ArchitecturalRendering25,
    image40: ArchitecturalRendering40,
    image: ArchitecturalRendering,
    serviceUrl: 'architecturalRendering',
  },
  {
    title: 'Online Identity',
    image2: OnlineIdentity2,
    image5: OnlineIdentity5,
    image10: OnlineIdentity10,
    image25: OnlineIdentity25,
    image40: OnlineIdentity40,
    image: OnlineIdentity,
    serviceUrl: 'onlineIdentity',
  },
  {
    title: 'Video Production',
    image2: VideoProduction2,
    image5: VideoProduction5,
    image10: VideoProduction10,
    image25: VideoProduction25,
    image40: VideoProduction40,
    image: VideoProduction,
    serviceUrl: 'videoProduction',
  },
  {
    title: 'Real Estate Expertise',
    image2: RealEstate2,
    image5: RealEstate5,
    image10: RealEstate10,
    image25: RealEstate25,
    image40: RealEstate40,
    image: RealEstate,
    serviceUrl: 'realEstateExpertise',
  },
  {
    title: 'Utility Tokens',
    image2: Token2,
    image5: Token5,
    image10: Token10,
    image25: Token25,
    image40: Token40,
    image: Token,
    serviceUrl: 'utilityTokens',
  },
  {
    title: 'Home Staging',
    image2: HomeStaging2,
    image5: HomeStaging5,
    image10: HomeStaging10,
    image25: HomeStaging25,
    image40: HomeStaging40,
    image: HomeStaging,
    serviceUrl: 'homeStaging',
  },
  {
    title: 'Websites & Applications',
    image2: Applications2,
    image5: Applications5,
    image10: Applications10,
    image25: Applications25,
    image40: Applications40,
    image: Applications,
    serviceUrl: 'applications',
  },
  {
    title: 'Digital Rendering',
    image2: Rendering2,
    image5: Rendering5,
    image10: Rendering10,
    image25: Rendering25,
    image40: Rendering40,
    image: Rendering,
    serviceUrl: 'rendering',
  },
];
