import { IReason } from '../../../../interfaces';
import './index.css';

const Reason = ({ image, title, description }: IReason) => {
  return (
    <div id="why-reason" className="grid grid-cols-1 lg:grid-cols-2 items-stretch">
      <div className="info">
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
      <div className="flex justify-end h-1/2">
        <img
          src={image}
          alt="why-reason-banner"
          className="lg:h-full lg:w-auto object-cover"
        />
      </div>
    </div>
  );
};

export default Reason;
