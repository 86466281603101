import { t } from 'i18next';
import './services-pages.css';
import { useTranslation } from 'react-i18next';
import WashingLight from '../../../assets/videos/Washing light.mp4';

const VideoProduction = () => {
  const { t } = useTranslation();
  return (
    <div id="services-video-production-page-layer1">
      <div id="services-page-layer2">
        <div id="services-page-layer3">
          <video className="w-full absolute top-0 left-0 -z-1" autoPlay muted loop>
            <source src={WashingLight} type="video/mp4" />
          </video>
          <div className="wrapper">
            <div className="absolute top-[24%] left-[5%] z-1">
              <div className="title">
                <h2>{t('page.services.video_production.title')}</h2>
              </div>
              <div className="subtitle">
                {t('page.services.video_production.description_1')}
              </div>
              <div className="subtitle2">
                {t('page.services.video_production.description_2')}
              </div>
              <div className="services-title">{t('page.services.our_work_included')}</div>
              <div className="space-y-4 pt-5">
                {/* line1 */}
                <div className="flex space-x-4">
                  <div className="services-bar">
                    {t('page.services.music_production')}
                  </div>
                  <div className="services-bar">
                    {t('page.services.artist_management')}
                  </div>
                </div>
                {/* line2 */}
                <div className="flex space-x-4">
                  <div className="services-bar">{t('page.services.music_promotion')}</div>
                  <div className="services-bar">
                    {t('page.services.content_creation')}
                  </div>
                </div>
                {/* line3 */}
                <div className="flex">
                  <div className="services-bar">
                    {t('page.services.event_organization')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoProduction;
