import { ILocation } from '../../../../interfaces';
import './index.css';

const Location = ({ title, subtitle, image }: ILocation) => {
  return (
    <div className="book-location">
      <h4>{title}</h4>
      <span>{subtitle}</span>
    </div>
  );
};

export default Location;
