import { useTranslation } from 'react-i18next';
import './Partners.style.css';
// @ts-ignore
const images = require.context(
  '../../../../assets/images/brand',
  false,
  /\.(png|jpe?g|svg)$/
);
const imageList = images.keys().map((image: string) => images(image));

const Partners = () => {
  const { t } = useTranslation();
  return (
    <div id="home-partners">
      <div className="section-top">
        <h3>{t('page.home.partners.title')}</h3>
      </div>
      <div className="section-bottom">
        <h3>{t('page.home.partners.sub_title')}</h3>
        <div className="partners">
          {imageList.map((img: string, key: number) => (
            <img key={key} src={img} alt="" className="partner" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
