import './services-pages.css';
import { useTranslation } from 'react-i18next';

const MusicProduction = () => {
  const { t } = useTranslation();
  return (
    <div id="services-event-production-page-layer1">
      <div id="services-page-layer2" className="overflow-hidden">
        <div className="wrapper">
          <div className="pt-[15%] pl-[5%]">
            <div className="title text-8xl">
              <h2 className="text-5xl">{t('page.services.event_production.title')}</h2>
            </div>
            <div className="space-y-4 justify-center pt-10">
              {/* line1 */}
              <div className="flex flex-wrap space-x-4">
                <div className="services-bar">
                  {t('page.services.full_service_event_organization')}
                </div>
                <div className="services-bar">{t('page.services.vip_venue_access')}</div>
              </div>
              {/* line2 */}
              <div className="flex flex-wrap space-x-4">
                <div className="services-bar">{t('page.services.experience_design')}</div>
                <div className="services-bar">{t('page.services.creative_concept')}</div>
              </div>
            </div>
            <div className="w-[50%] lg:w-[43%] text-3xl pt-20 font-Inter text-white">
              {t('page.services.event_production.description')}{' '}
              <b>{t('page.services.event_production.description2')}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MusicProduction;
