import './services-pages.css';
import { useTranslation } from 'react-i18next';

const EventProduction = () => {
  const { t } = useTranslation();
  return (
    <div id="services-music-production-page-layer1">
      <div id="services-page-layer2" className="overflow-hidden">
        <div className="wrapper">
          <div className="pt-[15%] pl-[5%]">
            <div className="title text-8xl">
              <h2 className="text-5xl">{t('page.services.music_production.title')}</h2>
            </div>
            <div className="space-y-4 justify-center pt-10">
              {/* {t('page.services.music_production.services').map(
                (service: string, index: number) => (
                  <div key={index} className="services-bar">
                    {service}
                  </div>
                )
              )} */}
              {/* line1 */}
              <div className="flex flex-wrap space-x-4">
                <div className="services-bar">
                  {t('page.services.music_production.services.music_production')}
                </div>
                <div className="services-bar">
                  {t('page.services.music_production.services.sound_design')}
                </div>{' '}
                <div className="services-bar">
                  {t('page.services.music_production.services.ghost_production')}
                </div>{' '}
              </div>
              {/* line2 */}
              <div className="flex flex-wrap space-x-4">
                <div className="services-bar">
                  {t('page.services.music_production.services.dj_booking')}
                </div>
                <div className="services-bar">
                  {t('page.services.music_production.services.mixing_and_mastering')}
                </div>{' '}
                <div className="services-bar">
                  {t('page.services.music_production.services.studio_rentals')}
                </div>{' '}
                <div className="services-bar">
                  {t('page.services.music_production.services.video_production')}
                </div>{' '}
              </div>
            </div>
            <div className="w-[50%] lg:w-[43%] text-3xl pt-20 font-Inter text-white">
              {t('page.services.music_production.description_1')}{' '}
              <b>{t('page.services.music_production.description_2')}</b>
              {t('page.services.music_production.description_3')}{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventProduction;
