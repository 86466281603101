import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppButton from '../../../../components/AppButton';
import { Download } from '../../../../lib/icons';
import './Portfolio.style.css';
import SokailDeck from '../../../../assets-user-triggered/decks/Sokail.pdf';
import portfolioHeaderWordHoverSound from '../../../../assets/sounds/PortfolioHeaderWordSound.wav';

const Portfolio = () => {
  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const enableInteraction = () => setHasInteracted(true);
    window.addEventListener('click', enableInteraction);

    return () => {
      window.removeEventListener('click', enableInteraction);
    };
  }, []);

  const playSound = () => {
    const audio = new Audio(portfolioHeaderWordHoverSound);
    if (hasInteracted) {
      audio.play().catch((error) => console.error('Error playing sound:', error));
    }
  };

  const { t } = useTranslation();

  return (
    <div id="home-portfolio">
      {/* <video className="background-video mb-400" autoPlay loop muted poster={PortfolioBG}>
        <source src={VideoExclusive} type="video/mp4" />
      </video> */}
      <div className="wrapper">
        {/* <MagnifyingGlass text="This is some text that will be magnified.">
          {' '}
          {t('page.home.portfolio.subtext')}{' '}
        </MagnifyingGlass> */}
        <h4 className="w-full break-words">
          {' '}
          {t('page.home.portfolio.subtext')
            .split(' ')
            .map((word: string, index: number) => (
              <span
                className="text-magnify"
                key="{index}"
                onMouseEnter={playSound}
                onTouchStart={playSound}
              >
                {word}
              </span>
            ))}
        </h4>
        <AppButton
          label={t('control.download_portfolio')}
          icon={<Download />}
          onClick={() => {
            window.open(SokailDeck, '_blank');
          }}
        />
      </div>
    </div>
  );
};

export default Portfolio;
