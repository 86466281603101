import { useEffect, useState } from 'react';
import { IProject } from '../../../../interfaces';
import flipSound from '../../../../../../assets/sounds/lightClap.mp3';
import './index.css';

const ProjectCard = ({ title, image, description }: IProject) => {
  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const enableInteraction = () => setHasInteracted(true);
    window.addEventListener('click', enableInteraction);

    return () => {
      window.removeEventListener('click', enableInteraction);
    };
  }, []);

  const playProjectFlipSound = () => {
    const audio = new Audio(flipSound);
    audio.volume = 0.5; // Set volume to 50%
    if (hasInteracted) {
      audio.play().catch((error) => console.error('Error playing sound:', error));
    }
  };

  return (
    // <div className="projects-project-card">
    //   <img alt={title} src={image} />
    //   {/* <span style={{backgroundImage: `url(${image})`}} /> */}
    //   <h4>{title}</h4>
    //   <p>{description}</p>
    // </div>
    <div
      className="projects-project-card flip-container"
      onMouseEnter={playProjectFlipSound}
    >
      <div className="flipper">
        <div className="front">
          <img src={image} alt="Front" />
        </div>
        <div className="back">
          <img src={image} alt="Back" />
        </div>
      </div>
      <div className="sizing-element">
        <img src={image} alt="Sizing" />
      </div>
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  );
};

export default ProjectCard;
