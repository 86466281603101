import { useState, useEffect, useContext } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppDropdown from '../../components/AppDropdown';
import { languages } from '../../lib/constants';
import AppContext from '../../contexts/AppContext';
import SokaiMini from '../../assets/images/logo.svg';
import MenuBtnOpen from '../../assets/images/control/btn_menu_open.png';
import MenuBtnClose from '../../assets/images/control/btn_menu_close.png';
import pageTurnSound from '../../assets/sounds/pageTurn.wav';
import globalImpactSound from '../../assets/sounds/globalImpact.wav';

import './index.css';
import { ILang } from '../../lib/interfaces';

const AppHeader = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { setOnContactDlg } = useContext(AppContext);

  const [scroll, setScroll] = useState<number>(0);
  const [headerTop, setHeaderTop] = useState<number>(0);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [selectedLang, setSelectedLang] = useState<ILang>(languages[0]);

  const handleSelectLanguage = (val: ILang) => {
    setSelectedLang(val);
    i18n.changeLanguage(val.code);
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    const header = document.getElementById('app-header');
    setHeaderTop(header?.offsetTop ?? 0);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const enableInteraction = () => setHasInteracted(true);
    window.addEventListener('click', enableInteraction);

    return () => {
      window.removeEventListener('click', enableInteraction);
    };
  }, []);

  const playPageTurnSound = () => {
    const audio = new Audio(pageTurnSound);
    if (hasInteracted) {
      audio.play().catch((error) => console.error('Error playing sound:', error));
    }
  };

  const playMobileMenuSound = () => {
    const audio = new Audio(globalImpactSound);
    if (hasInteracted) {
      audio.play().catch((error) => console.error('Error playing sound:', error));
    }
  };

  const linkClass = 'nav-btn';
  const activeLinkClass = 'active-nav-link'; // This applies light grey color

  return (
    <header
      id="app-header"
      className={`${scroll > headerTop ? 'stick' : ''} ${
        isOpen ? 'mobile-menu-open' : ''
      }`}
    >
      <div className="wrapper app-flex-between">
        <img
          aria-hidden
          className="logo app-btn"
          src={SokaiMini}
          alt="logo"
          onClick={() => {
            navigate('/');
          }}
        />
        <div id="nav-bar">
          {/* <Link className="nav-btn" to="/" onMouseEnter={playPageTurnSound}>
            {t('control.home')}
          </Link> */}

          <NavLink
            className={({ isActive }) =>
              isActive ? `${linkClass} ${activeLinkClass}` : linkClass
            }
            to="/services"
            onMouseEnter={playPageTurnSound}
          >
            {t('control.services')}
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? `${linkClass} ${activeLinkClass}` : linkClass
            }
            to="/portfolio"
            onMouseEnter={playPageTurnSound}
          >
            {t('control.portfolio')}
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? `${linkClass} ${activeLinkClass}` : linkClass
            }
            to="/team"
            onMouseEnter={playPageTurnSound}
          >
            {t('control.team')}
          </NavLink>
          {/* <NavLink
            className={({ isActive }) =>
              isActive ? `${linkClass} ${activeLinkClass}` : linkClass
            }
            to="/metaverse"
            onMouseEnter={playPageTurnSound}
          >
            {t('control.metaverse')}
          </NavLink> */}
          <NavLink
            className={({ isActive }) =>
              isActive ? `${linkClass} ${activeLinkClass}` : linkClass
            }
            to="/venues "
            onMouseEnter={playPageTurnSound}
          >
            {t('control.venues')}
          </NavLink>
          <h1
            onMouseEnter={playPageTurnSound}
            aria-hidden
            className="nav-btn app-btn"
            onClick={() => {
              setOnContactDlg(true);
            }}
          >
            {t('control.contact')}
          </h1>
          <AppDropdown
            values={languages}
            selectItem={handleSelectLanguage}
            selectedItem={selectedLang}
          />
        </div>
        <div className="nav-bar-control mobile-view-set">
          {!isOpen && (
            <div className="mobile-view">
              <AppDropdown
                values={languages}
                selectItem={handleSelectLanguage}
                selectedItem={selectedLang}
              />
            </div>
          )}
          <button
            id="menu-btn"
            className="app-btn"
            onClick={() => {
              setOpen((prev) => !prev);
              playMobileMenuSound();
            }}
          >
            <img src={isOpen ? MenuBtnClose : MenuBtnOpen} alt="menu-btn" />
          </button>
        </div>
      </div>
      {/* navbar for mobile view */}
      {isOpen && (
        <div id="nav-bar-mobile">
          <Link
            className="nav-btn-mobile app-btn"
            to="/"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t('control.home')}
          </Link>
          <Link
            className="nav-btn-mobile app-btn"
            to="/services"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t('control.services')}
          </Link>
          <Link
            className="nav-btn-mobile app-btn"
            to="/portfolio"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t('control.portfolio')}
          </Link>
          <Link
            className="nav-btn-mobile app-btn"
            to="/team"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t('control.team')}
          </Link>
          <Link
            className="nav-btn-mobile app-btn"
            to="/venues"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t('control.venues')}
          </Link>
          <h1
            aria-hidden
            className="nav-btn-mobile app-btn"
            onClick={() => {
              setOpen(false);
              setOnContactDlg(true);
            }}
          >
            {t('control.contact')}
          </h1>
        </div>
      )}
    </header>
  );
};
export default AppHeader;
