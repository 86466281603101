import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// import MenuEl from './components/MenuEl';
import Fabi from '../../assets/team/Fabi.gif';
import Josh from '../../assets/team/Josh.gif';
import Marko from '../../assets/team/Marko.gif';
import Tariq from '../../assets/team/Tariq.gif';
import Wayne from '../../assets/team/Wayne.gif';
import Sara from '../../assets/team/Sara.gif';
import Krystal from '../../assets/team/Krystal.gif';
import Florian from '../../assets/team/Florian.gif';
import Trifon from '../../assets/team/Trifon.gif';
import Adrian from '../../assets/team/Adrian.gif';
// import Petter from '../../assets/team/Petter.gif';
import Prasad from '../../assets/team/Prasad.gif';
import Russel from '../../assets/team/Russel.gif';
import BlueBeacon from '../../assets/videos/Blue beacon trimmed.mp4';
import teamSound from '../../assets/sounds/teamSound.wav';

const Team = () => {
  const [currentlyPlaying, setCurrentlyPlaying] = useState<string | null>(null);
  const audioRefs = useRef<{ [key: string]: HTMLAudioElement }>({});

  const location = useLocation();
  //   const [title] = useState<string | null>(location.state.title);
  //   const [subTitle] = useState<string | null>(location.state.subTitle);
  //   const [description] = useState<string | null>(location.state.description);
  //   const [image] = useState<string | null>(location.state.image);
  //   const [menu] = useState<string[] | null>(location.state.menu);

  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const enableInteraction = () => setHasInteracted(true);
    window.addEventListener('click', enableInteraction);

    return () => {
      window.removeEventListener('click', enableInteraction);
    };
  }, []);

  const fadeOutAudio = (audioRef: HTMLAudioElement) => {
    const originalVolume = audioRef.volume;
    const fadeOutInterval = 50; // milliseconds
    const fadeOutStep = originalVolume / (8000 / fadeOutInterval);
    console.log('fadeOutStep:', fadeOutStep);
    const fadeAudio = setInterval(() => {
      if (audioRef.volume > fadeOutStep) {
        audioRef.volume -= fadeOutStep;
      } else {
        // When volume is low enough, stop the interval and set volume to 0
        clearInterval(fadeAudio);
        audioRef.volume = 0;
        audioRef.pause(); // Optionally pause the audio after fading out
        audioRef.currentTime = 0; // Reset audio position for next play
      }
    }, fadeOutInterval);
  };

  const playSound = () => {
    const soundId = 'teamSound';
    const soundUrl = teamSound;
    if (currentlyPlaying && audioRefs.current[currentlyPlaying]) {
      const currentAudio = audioRefs.current[currentlyPlaying];
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }

    // Check if the sound is already loaded, if not, load it
    if (!audioRefs.current[soundId]) {
      audioRefs.current[soundId] = new Audio(soundUrl);
      fadeOutAudio(audioRefs.current[soundId]); // Fade out over X milliseconds
    }

    const audio = audioRefs.current[soundId];

    // Play the new sound if the user has interacted with the page
    if (hasInteracted) {
      audio.play().catch((error) => console.error('Error playing sound:', error));
      setCurrentlyPlaying(soundId); // Update the currently playing sound ID
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="team-page" className="pt-20">
      <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
        {/* Fabiano Valli - Adjusted for mobile full height */}
        <div
          className="w-full sm:w-auto sm:h-auto overflow-hidden"
          onMouseEnter={playSound}
          // style={{ minHeight: '100vh' }}
        >
          <div className="aspect-square overflow-hidden rounded-lg mx-auto">
            <img className="w-full h-full object-cover" src={Fabi} alt="Fabi" />
          </div>
          {/* <div className="border-2 rounded-lg m-3 pt-2 pb-2 text-center">
            <h1>Fabiano Valli</h1>
            <p className="text-sm">Senior Executive Director</p>
          </div> */}
        </div>
        {/* Josh Kornreich */}
        <div className="" onMouseEnter={playSound}>
          <div className="aspect-square overflow-hidden rounded-lg mx-auto">
            <img className="w-full h-full object-cover" src={Josh} alt="Josh" />
          </div>
          {/* <div className="border-2 rounded-lg m-3 pt-2 pb-2 text-center">
            <h1>Josh Kornreich</h1>
            <p className="text-sm">Chief of Operations and Sales</p>
          </div> */}
        </div>
        {/* Marko Velbl */}
        <div className="" onMouseEnter={playSound}>
          <div className="aspect-square overflow-hidden rounded-lg mx-auto">
            <img className="w-full h-full object-cover" src={Marko} alt="Marko" />
          </div>
          {/* <div className="border-2 rounded-lg m-3 pt-2 pb-2 text-center">
            <h1>Marko Velbl</h1>
            <p className="text-sm">Chief Creative Director</p>
          </div> */}
        </div>
        {/* Tariq al Habtoor */}
        <div className="" onMouseEnter={playSound}>
          <div className="aspect-square overflow-hidden rounded-lg mx-auto">
            <img className="w-full h-full object-cover" src={Tariq} alt="Tariq" />
          </div>
          {/* <div className="border-2 rounded-lg m-3 pt-2 pb-2 text-center">
            <h1>Tariq al Habtoor</h1>
            <p className="text-sm">Head of Middle East</p>
          </div> */}
        </div>
        {/* Wayne Witzel */}
        <div className="" onMouseEnter={playSound}>
          <div className="aspect-square overflow-hidden rounded-lg mx-auto">
            <img className="w-full h-full object-cover" src={Wayne} alt="Wayne" />
          </div>
          {/* <div className="border-2 rounded-lg m-3 pt-2 pb-2 text-center">
            <h1>Wayne Witzel</h1>
            <p className="text-sm">Chief Technology Officer</p>
          </div> */}
        </div>
        {/* Sara Kornreich */}
        <div className="" onMouseEnter={playSound}>
          <div className="aspect-square overflow-hidden rounded-lg mx-auto">
            <img className="w-full h-full object-cover" src={Sara} alt="Sara" />
          </div>
          {/* <div className="border-2 rounded-lg m-3 pt-2 pb-2 text-center">
            <h1>Sara Kornreich</h1>
            <p className="text-sm">Head of US Operations</p>
          </div> */}
        </div>
        {/* Krystal Yang */}
        <div className="" onMouseEnter={playSound}>
          <div className="aspect-square overflow-hidden rounded-lg mx-auto">
            <img className="w-full h-full object-cover" src={Krystal} alt="Krystal" />
          </div>
          {/* <div className="border-2 rounded-lg m-3 pt-2 pb-2 text-center">
            <h1>Krystal Yang</h1>
            <p className="text-sm">CEO of Ignite Tournaments</p>
          </div> */}
        </div>
        {/* Florian Reike */}
        <div className="" onMouseEnter={playSound}>
          <div className="aspect-square overflow-hidden rounded-lg mx-auto">
            <img className="w-full h-full object-cover" src={Florian} alt="Florian" />
          </div>
          {/* <div className="border-2 rounded-lg m-3 pt-2 pb-2 text-center">
            <h1>Florian Reike</h1>
            <p className="text-sm">Chief Blockchain Consultant</p>
          </div> */}
        </div>
        {/* Trifon Gatev */}
        <div className="" onMouseEnter={playSound}>
          <div className="aspect-square overflow-hidden rounded-lg mx-auto">
            <img className="w-full h-full object-cover" src={Trifon} alt="Krystal" />
          </div>
          {/* <div className="border-2 rounded-lg m-3 pt-2 pb-2 text-center">
            <h1>Trifon Gatev</h1>
            <p className="text-sm">Lead Engineering Manager</p>
          </div> */}
        </div>
        {/* Adrian Lyubenov */}
        <div className="" onMouseEnter={playSound}>
          <div className="aspect-square overflow-hidden rounded-lg mx-auto">
            <img className="w-full h-full object-cover" src={Adrian} alt="Krystal" />
          </div>
          {/* <div className="border-2 rounded-lg m-3 pt-2 pb-2 text-center">
            <h1>Adrian Lyubenov</h1>
            <p className="text-sm">Lead Unity Developer</p>
          </div> */}
        </div>
        {/* Prasad Kumkar */}
        <div className="" onMouseEnter={playSound}>
          <div className="aspect-square overflow-hidden rounded-lg mx-auto">
            <img className="w-full h-full object-cover" src={Prasad} alt="Prasad" />
          </div>
          {/* <div className="border-2 rounded-lg m-3 pt-2 pb-2 text-center">
            <h1>Prasad Kumkar</h1>
            <p className="text-sm">Senior Blockchain Consultant</p>
          </div> */}
        </div>
        {/* Russel Gesolgon */}
        <div className="" onMouseEnter={playSound}>
          <div className="aspect-square overflow-hidden rounded-lg mx-auto">
            <img className="w-full h-full object-cover" src={Russel} alt="Krystal" />
          </div>
          {/* <div className="border-2 rounded-lg m-3 pt-2 pb-2 text-center">
            <h1>Russel Gesolgon</h1>
            <p className="text-sm">Senior Executive Assistant</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Team;
