import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// import MenuEl from './components/MenuEl';
// POTENTIALLY UNUSED //
import IgnitePitchDeckOpening from '../../assets/portfolio/decks/covers/Ignite Pitch Deck Opening.gif';
import ChainscorePitchDeckOpening from '../../assets/portfolio/decks/covers/Chainscore Pitch Deck Opening.gif';
import AstracePitchDeckOpening from '../../assets/portfolio/decks/covers/Astrace Pitch Deck Opening.gif';
import KhloudPitchDeckOpening from '../../assets/portfolio/decks/covers/Khloud Pitch Deck Opening.gif';
import SpotnikPitchDeckOpening from '../../assets/portfolio/decks/covers/Spotnik Pitch Deck Opening.gif';
import LostClubToysOpening from '../../assets/portfolio/decks/covers/LCT Pitch Deck Opening.gif';
import LeMagasinOpening from '../../assets/portfolio/decks/covers/Le Magasin Pitch Deck Opening.gif';
import XoraPitchDeckOpening from '../../assets/portfolio/decks/covers/Xora Pitch Deck Opening.gif';
import BlueBeacon from '../../assets/videos/Blue beacon trimmed.mp4';

const Decks = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="portfolio-page" className="pt-20">
      <div className="grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 aspect-auto">
        <div className="">
          <img className="auto auto" src={IgnitePitchDeckOpening} alt="Ignite"></img>
        </div>
        <div className="">
          <img
            className="auto auto"
            src={ChainscorePitchDeckOpening}
            alt="Chainscore"
          ></img>
        </div>
        <div className="">
          <img className="auto auto" src={AstracePitchDeckOpening} alt="Astrace"></img>
        </div>
        <div className="">
          <img className="auto auto" src={KhloudPitchDeckOpening} alt="Khloud"></img>
        </div>
        <div className="">
          <img
            className="auto auto"
            style={{ width: '100%' }}
            src={SpotnikPitchDeckOpening}
            alt="Spotnik"
          ></img>
        </div>
        <div className="">
          <img className="auto auto" src={LostClubToysOpening} alt="LCT"></img>
        </div>
        <div className="">
          <img className="auto auto" src={LeMagasinOpening} alt="Le Magasin"></img>
        </div>
        <div className="">
          <img className="auto auto" src={XoraPitchDeckOpening} alt="Xora"></img>
        </div>
      </div>
      <div id="partner-name" className="grid grid-cols-4 text-center text-2xl"></div>
      <div className="video-container">
        <div className=""></div>
        <video className="w-full opacity-30" autoPlay muted loop>
          <source src={BlueBeacon} type="video/mp4" />
        </video>
        {/* <div className="bottom-fade"></div> */}
      </div>
    </div>
  );
};

export default Decks;
