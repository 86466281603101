import { ILocation, IProject, IReason, IService } from './interfaces';

/** images for carousel */
import DJImage from '../../assets/images/home/why/dj.jpg';
import AIImage from '../../assets/images/home/why/ai.png';
import MetaImage from '../../assets/images/home/why/meta.png';
import MusicImage from '../../assets/images/home/why/music.png';
import PioneersImage from '../../assets/images/home/why/pioneers.png';

/** images for locations */
import EmanuleImage from '../../assets/images/home/book/emanule.jpg';
import ConversoImage from '../../assets/images/home/book/converso.jpg';
import LabirintoImage from '../../assets/images/home/book/labirinto.jpg';
import CalcioneImage from '../../assets/images/home/book/calcione.jpg';
import CastelDelMonteImage from '../../assets/images/home/book/castel-del-monte.jpg';
import ChiesaImage from '../../assets/images/home/book/chiesa.jpg';
import ViscontiImage from '../../assets/images/home/book/visconti.jpg';

/** images for projects */
import VersaceImage from '../../assets/images/home/projects/versace.jpg';
import PreliosImage from '../../assets/images/home/projects/prelios.jpg';
import PPImage from '../../assets/images/home/projects/pp.jpg';
import PradaImage from '../../assets/images/home/projects/prada.jpg';
import JustImage from '../../assets/images/home/projects/just.jpg';
import PLSTCImage from '../../assets/images/home/projects/plstc_art_basel.png';

export const reasons: IReason[] = [
  {
    title: "Personal relationships with the world's top DJs",
    description: `We elevate market-defining brands through
                    comprehensive creative solutions to empower their growth,
                    make great impact and bring magic into the world.`,
    image: DJImage,
  },
  {
    // title: 'Pioneers in integrating VR into real estate for Prelios',
    title: 'Pioneering construction',
    description: `We have contributed to changing the course of the entire real estate industry.`,
    image: PioneersImage,
  },
  {
    title: 'True metaverse production as they were intended to be',
    description: `We elevate market-defining brands through
                    comprehensive creative solutions to empower their growth,
                    make great impact and bring magic into the world.`,
    image: MetaImage,
  },
  {
    title: 'Avatar generation in partnership with IGOODI with actual depth',
    description: `We elevate market-defining brands through
                    comprehensive creative solutions to empower their growth,
                    make great impact and bring magic into the world.`,
    image: AIImage,
  },
  {
    title: 'Music production, for everything, by masters',
    description: `We elevate market-defining brands through comprehensive
                    creative solutions to empower their growth,
                    make great impact and bring magic into the world.`,
    image: MusicImage,
  },
];

export const locations: ILocation[] = [
  {
    title: 'Castel Del Monte',
    subtitle: 'PUGLIA, LOCATION WHERE GAME OF THRONES WAS FILMED',
    image: CastelDelMonteImage,
  },
  {
    title: 'Chiesa Sconsacrata Via Piero Della Francesca',
    subtitle: 'MILANO',
    image: ChiesaImage,
  },
  {
    title: 'Castello Visconti Sforza',
    subtitle: 'MILANO',
    image: ViscontiImage,
  },
  {
    title: 'Castello del Calcione',
    subtitle: 'FIRENZE',
    image: CalcioneImage,
  },
  {
    title: 'Castello di Tolcinasco Pieve Emanuele',
    subtitle: 'MILANO',
    image: EmanuleImage,
  },
  {
    title: 'Chiesa San Paolo Converso',
    subtitle: 'MILANO',
    image: ConversoImage,
  },
  {
    title: 'Labirinto Della Masone, Parma',
    subtitle: 'PARMA',
    image: LabirintoImage,
  },
];

export const projects: IProject[] = [
  {
    title: 'VERSACE',
    description:
      'Out team handled the entirety of the souund selextion for sll party and runnway events, music production for private parties',
    image: VersaceImage,
  },
  {
    title: 'PRELIOS',
    description: `Prelios is the Pirelli real estate department.
                    As their media content creator for four years, we were responsible for developing all of their visual materials,
                    including images, drawings and 3D renderings.`,
    image: PreliosImage,
  },
  {
    title: 'PHILIPP PLEIN',
    description: `For the Philipp Plein’s Urban Jungle Fashion Show 2012,
                    we were responsible for the complete music setup, PR and entertainment. Wecreated the vibe with the music for everything from the show up through the after party.`,
    image: PPImage,
  },
  {
    title: 'PUNKS WEAR PRADA',
    description: `We were one of the primary partners and founders of the most significant party in Milan for over 10 year, PWP, with Natasha Slater
                       dedicated to models, actors, celebrities, and fashionistast. The party took place for over ten years, and were responsible for all aspects of the music art direction`,
    image: PradaImage,
  },
  // {
  //   title: 'SOCIAL MUSIC CITY',
  //   description: `Social Music City is a music festival in Italy that gathers the biggest names from the electronic music scene:
  //                 Tale of Us, Marco Carola, Richie Hawtin, Charlotte de Witte, Chris Liebing, Enrico Sangiuliano,
  //                 Anfisa Letyago, Pisetzky and many more. The festival was sold in 2017. `,
  //   image: SMCImage
  // },
  // {
  //   title: 'San Patrignano Community',
  //   description: `San Patrignano Community is the biggest addiction recovery community in Italy.
  //                 Our work included Media and Marketing activities of the whole community.`,
  //   image: SPCImage
  // },
  // {
  //   title: 'Vittorio Grigolo',
  //   description: `We had a pleasure to be in charge of music production for the latest upcoming album for Vittorio Grigolo,
  //                 world famous lyrical singer.
  //                 The album we worked on was recorded in Japan.`,
  //   image: VittorioImage
  // },
  {
    title: 'Just This Record Label',
    description: `Just This is the expression of a social, metropolitan and underground movement we are living and going through all together.
                    Just This is run by the duo Hunter/Game & Pisetzky from 12 Years,
                    and had the pleasure to release some of the best artists In the music industry.`,
    image: JustImage,
  },
  {
    title: 'PLSTC at Art Basel Miami',
    description: `During the second Art Basel in Miami,
                    PLSTC event presented to its public installations and photos that gravitate around the plastic theme,
                    all soundtracked by DJ and live sets. The event was organized by Pisetzky.`,
    image: PLSTCImage,
  },
];
