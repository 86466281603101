import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IProject } from '../../interfaces';
import { projects } from '../../constants';
import ProjectCard from './components/ProjectCard';

import './Projects.style.css';

const moreProjects: IProject[] = [];

const Projects = (playProjectFlipSound: any) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  return (
    <div id="home-projects">
      <h2>{t('page.home.projects.title')}</h2>
      <div className="wrapper">
        {Object.values(projects).map((project: IProject, key: number) => (
          <ProjectCard
            image={project.image}
            title={project.title}
            description={project.description}
            key={key}
          />
        ))}
        {showMore &&
          Object.values(moreProjects).map((project: IProject, key: number) => (
            <ProjectCard
              image={project.image}
              title={project.title}
              description={project.description}
              key={key}
            />
          ))}
      </div>
    </div>
  );
};

export default Projects;
