import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import AppContext from '../../contexts/AppContext';
import AppInput from '../../components/AppInput';
import AppTextarea from '../../components/AppTextArea';
import AppButton from '../../components/AppButton';

import { FORM_VALIDATION } from '../../lib/utils';
import { MESSAGE_MAX_LENGTH } from '../../lib/constants';

import { ArrowRight, Close } from '../../lib/icons';

import './index.css';

const AppContact = () => {
  const { onContactDlg, setOnContactDlg } = useContext(AppContext);
  const [name, setName] = useState<string>('');
  const [errName, setErrName] = useState<string | null>(null);
  const [email, setEmail] = useState<string>('');
  const [errEmail, setErrEmail] = useState<string | null>(null);
  const [project, setProject] = useState<string>('');
  const [errProject, setErrProject] = useState<string | null>(null);
  const [business, setBusiness] = useState<string>('');
  const [errBusiness, setErrBusiness] = useState<string | null>(null);
  const [message, setMessage] = useState<string>('');
  const [errMessage, setErrMessage] = useState<string | null>(null);
  const [phone, setPhone] = useState<string>('');
  const [errPhone, setErrPhone] = useState<string | null>(null);

  const validateForm = () => {
    const resultName = FORM_VALIDATION.required(name);
    const resultEmail = FORM_VALIDATION.email(email);
    const resultPhone = FORM_VALIDATION.required(phone);
    const resultProject = FORM_VALIDATION.required(project);
    const resultBusiness = FORM_VALIDATION.required(business);
    const resultMessage = FORM_VALIDATION.message(message, MESSAGE_MAX_LENGTH);

    setErrName(resultName);
    setErrEmail(resultEmail);
    setErrProject(resultProject);
    setErrBusiness(resultBusiness);
    setErrMessage(resultMessage);
    setErrPhone(resultPhone);

    if (
      !resultName &&
      !resultEmail &&
      !resultProject &&
      !resultBusiness &&
      !resultMessage &&
      !resultPhone
    )
      return true;
    else return false;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      console.log('form submitted successfully!');
      clearForm();
    }
  };

  const clearForm = () => {
    setName('');
    setEmail('');
    setProject('');
    setBusiness('');
    setMessage('');
    setPhone('');
    setErrName(null);
    setErrEmail(null);
    setErrBusiness(null);
    setErrProject(null);
    setErrMessage(null);
    setErrPhone(null);
  };

  useEffect(() => {
    if (errName) setErrName(null);
  }, [name]);

  useEffect(() => {
    if (errEmail) setErrEmail(null);
  }, [email]);

  useEffect(() => {
    if (errProject) setErrProject(null);
  }, [project]);

  useEffect(() => {
    if (errBusiness) setErrBusiness(null);
  }, [business]);

  useEffect(() => {
    if (errMessage) setErrMessage(null);
  }, [message]);

  useEffect(() => {
    if (errPhone) setErrPhone(null);
  }, [phone]);

  return (
    <div
      id="contact-modal"
      className={`${onContactDlg ? 'contact-stick' : 'contact-hidden'}`}
    >
      <div className="wrapper">
        <div className="modal-header">
          <h4>Contact</h4>
          <div
            aria-hidden
            className="hover:cursor-pointer app-btn"
            onClick={() => {
              clearForm();
              setOnContactDlg(false);
            }}
          >
            <Close className="close-btn" />
          </div>
        </div>
        <h1 className="modal-title">Let&apos;s get in touch</h1>
        <h1 className="modal-subtitle">Enter your message information here</h1>
        <div className="modal-input-full-container">
          <AppInput
            placeholder="Name*"
            value={name}
            setValue={setName}
            type="text"
            required={true}
            error={errName}
          />
          <AppInput
            placeholder="Email Address*"
            value={email}
            setValue={setEmail}
            type="email"
            required={true}
            error={errEmail}
          />
          <AppInput
            placeholder="Phone Number*"
            value={phone}
            setValue={setPhone}
            type="number"
            required={true}
            error={errPhone}
          />
          <AppInput
            placeholder="Project Name*"
            value={project}
            setValue={setProject}
            type="text"
            required={true}
            error={errProject}
          />
          <AppInput
            placeholder="State of Business*"
            value={business}
            setValue={setBusiness}
            type="text"
            required={true}
            error={errBusiness}
          />
          <AppTextarea
            placeholder="Message*"
            value={message}
            setValue={setMessage}
            error={errMessage}
            required={true}
          />
          <AppButton
            label="Let us take you next level"
            onClick={() => {
              handleSubmit();
            }}
            icon={<ArrowRight />}
          />
        </div>
        <div className="modal-footer">
          <h1>
            Or if you prefer you can send us a email latter on &nbsp;
            <Link to="mailto:contact@sokai.media">contact@sokai.media</Link>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default AppContact;
